import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import useCreateUploadProcess from "./useCreateUploadProcess";
import Button from "react-bootstrap/Button";
import Loading from "../../components/Loading";
import {Alert} from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import moment from "moment";
import {standardDateTimeFormat} from "../../utils/momentHelpers";

export default function Finished() {
    const { user } = useAuth();
    const { isCreating, error: uploadError, create } = useCreateUploadProcess();

    return (
        <div>
            <h1>Your upload process was finished.</h1>
            <p>
                This process was finished on {moment(user?.upload_process?.updated_at).format(standardDateTimeFormat)}.
            </p>
            {uploadError && <Alert variant="danger">{uploadError}</Alert>}

            <Button disabled={isCreating} onClick={() => create()}>
                Start a new upload process&nbsp;
                {isCreating && <Loading />}
            </Button>
        </div>
    );
}

