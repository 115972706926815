import {routes} from "../../utils";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {UserModel} from "../../types/Models";

export default function navigateToStep(navigate: NavigateFunction, user: UserModel | null) {
    if (user?.upload_process?.deleted_at) {
        navigate(routes.uploadProcess);
    } else if (user?.upload_process?.step_number === 2) {
        navigate(routes.validateFormat);
    } else if (user?.upload_process?.step_number === 3) {
        navigate(routes.analysis);
    }  else if (user?.upload_process?.step_number === 4) {
        navigate(routes.uploadProcessFinished);
    } else if (user) {
        navigate(routes.uploadProcess);
    }
}
