import React, {useEffect, useRef, useState} from 'react';
import useAuth from "../../hooks/useAuth";
import Button from "react-bootstrap/Button";
import {Trash3} from "react-bootstrap-icons";
import axios, {CancelTokenSource} from "axios";
import Loading from "../Loading";
import Spinner from "react-bootstrap/Spinner";
import {Alert} from "react-bootstrap";

function DeleteUploadProcessButton() {
    const { user, reloadUser } = useAuth();
    const [isDeleting, setDeleting] = useState(false);
    const [error, setError] = useState(false);
    const cancelRef = useRef<CancelTokenSource|null>();
    const id = user?.upload_process?.id;

    const doDelete = () => {
        setDeleting(true);
        cancelRef.current = axios.CancelToken.source();
        axios.delete(`/upload-processes/${id}`, { cancelToken: cancelRef.current?.token })
            .then(() => {
                reloadUser();
            })
            .catch((e) => {
                setError(e.message);
            })
            .finally(() => {
                cancelRef.current = null;
                setDeleting(false);
            })
    };

    useEffect(() => {
        return () => {
            cancelRef.current?.cancel();
        }
    }, []);

    return id ? (
        <>
            <Button
                variant="outline-danger"
                className="float-start d-inline-flex align-items-center gap-2"
                disabled={isDeleting}
                onClick={doDelete}
            >
                <Trash3 />
                &nbsp;Restart process
                {isDeleting && <Spinner animation="border"/>}
            </Button>
            {error && <Alert variant="danger">{error}</Alert>}
        </>
    ) : null;
}

export default DeleteUploadProcessButton;
