import React, {useEffect} from 'react';
import {useNavigate} from "react-router";
import BackendForm from "../../components/forms/BackendForm";
import FloatingLabelField from "../../components/forms/FloatingLabelField";
import {routes, backendEndpoints} from "../../utils";
import useAuth from "../../hooks/useAuth";
import useTitle from "../../hooks/useTitle";
import {loginSchema} from "../../utils/validationSchemas";
import {Container} from "react-bootstrap";
import {isDev} from "../../utils/dev";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export default function Login() {
    useTitle('Log in')
    const {saveToken, user, isLoading} = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (!isLoading && user) navigate(routes.dashboard)
    }, [user, isLoading])

    return (
        <Container>
            <Row>
                <Col sm={{span: 6, offset: 3}}>
                    <BackendForm
                        initialValues={{
                            email_or_username: isDev() ? 'giguru.scheuer@gmail.com' : '',
                            password: isDev() ? 'password' : '',
                        }}
                        targetEndpoint={backendEndpoints.login()}
                        targetMethod="POST"
                        showFooter
                        validationSchema={loginSchema}
                        onSuccess={({data}) => {
                            saveToken(data.data.token);
                            // Hard refresh, such that the login cookie read into the react state;
                            window.location.href = data.two_factor ? routes.twoFactorChallenge : routes.dashboard;
                        }}
                        footerContent={(
                            <div className="py-2">
                                <a href={routes.forgotPassword}>Forgotten password?</a>
                            </div>
                        )}
                    >
                        <h1 className="gradient-color mb-4 text-center">Log in</h1>
                        <FloatingLabelField name="email_or_username" label="E-mail"/>
                        <FloatingLabelField type="password" name="password" label="Password"/>
                    </BackendForm>
                </Col>
            </Row>
        </Container>
    );
}
