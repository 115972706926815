import React, {useEffect, useState} from 'react';
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import navigateToStep from "./navigateToStep";
import {useNavigate} from "react-router";

function useCreateUploadProcess() {
    const { reloadUser, user } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [isCreating, setCreating] = useState(false);

    const create = () => {
        setError('');
        setCreating(true);

        return axios.post('/upload-processes')
            .then(() => {
                reloadUser()
            })
            .catch((e) => {
                setError(e.message);
            })
            .finally(() => {
                setCreating(false);
            });
    };

    const hasUploadProcess = Boolean(user?.upload_process) && !user?.upload_process?.deleted_at;

    useEffect(() => {
        if (user && !hasUploadProcess) {
            create();
        } else {
            navigateToStep(navigate, user);
        }
    }, [hasUploadProcess, user]);

    return { isCreating, error, create };
}

export default useCreateUploadProcess;
