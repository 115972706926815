import React from 'react';
import useGet from "../hooks/useGet";
import {backendEndpoints, routes} from "../utils";
import Table from "../components/layout/table/Table";
import {Column} from "react-table";
import {Alert} from "react-bootstrap";
import Loading from "../components/Loading";
import PageHeader from "../components/layout/page-header/PageHeader";
import AddButton from "../components/button/AddButton";
import {useNavigate} from "react-router";
import {GrantModel} from "../types/Models";
import PaginationBar from "../components/layout/PaginationBar";
import usePaginatedGet from "../hooks/usePaginatedGet";
import UsagePolicySpan from "../components/UsagePolicySpan";
import SpecificitySpan from "../components/SpecificitySpan";
import MoneyAmount from "../components/MoneyAmount";

const columns: Column<GrantModel>[] = [
    { accessor: 'name', Header: 'Name' },
    { accessor: 'total_amount', Header: 'Amount', Cell: ({ value }) => <MoneyAmount amount={value} rounding /> },
    { accessor: 'usage_policy', Header: 'Usage Policy', Cell: ({ value }) =>  <UsagePolicySpan id={value} />},
    { accessor: 'specificity', Header: 'Specificity', Cell: ({ value }) =>  <SpecificitySpan id={value} /> },
    { accessor: 'id', Header: 'ID' },
]

export default function Grants() {
    const navigate = useNavigate();
    const { data: grants, error, isLoading, pagination, pageIndex, setPageIndex } = usePaginatedGet<GrantModel[]>({
        endpoint: backendEndpoints.grants()+'?_with[]=group'
    });

    return (
        <>
            <PageHeader header="Grants">
                <AddButton onClick={() => navigate(routes.grant(':new'))} />
            </PageHeader>
            {pagination && (
                <PaginationBar
                    pageIndex={pageIndex}
                    setPageIndex={setPageIndex}
                    pagination={pagination}
                />
            )}
            {isLoading && <Loading />}
            {error && <Alert variant="danger">{error}</Alert>}
            <Table<GrantModel>
                memoisedData={Array.isArray(grants) ? grants : []}
                memoisedColumns={columns}
                buildLink={({ id }) => routes.grant(id)}
                maxHeight={0}
            />
        </>
    );
}
