import React, {CSSProperties} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Description.module.scss';

interface Props {
    maxWidth?: number
    className?: string
    children: string | string[] | JSX.Element | JSX.Element[]
}


export default function Description({ children, className, maxWidth }: Props) {
    const classObject : Record<string, boolean> = {
        'gray-700': true,
        [styles.Description]: true,
    };
    const style : CSSProperties = {};

    if (typeof className === 'string') {
        classObject[className] = true;
    }
    if (typeof maxWidth === 'number') {
        style['maxWidth'] = maxWidth;
    }

    return <p className={cx(classObject)} style={style}>{children}</p>;
}
