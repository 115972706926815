import {createContext, useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import {routes} from "../utils";
import {useNavigate} from "react-router";
import {UserModel} from "../types/Models";

export const tokenKey = 'authToken'

type CookieName = string
type CookieValue = string
type OptionalCookie = string | undefined;

const AuthContext = createContext<{
    user: null | UserModel
    isLoading: boolean
    reloadUser: () => void
    saveToken: (token: OptionalCookie) => void
    logout: () => void
}>({
    user: null,
    isLoading: false,
    reloadUser: () => {},
    saveToken: (token) => {},
    logout: () => {},
})

function setCookie(cname: CookieName, cvalue: CookieValue, hours = 3) {
    const d = new Date();
    d.setTime(d.getTime() + (hours*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function removeCookie(cname: CookieName) {
    setCookie(cname, '', -5)
}

function getCookie(cname: CookieName) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function AuthProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
    const [user, setUser] = useState(null)
    const [isLoading, setLoading] = useState(true)
    const [mounted, setMounted] = useState(false)
    const navigate = useNavigate()

    const saveToken = (newToken: OptionalCookie = undefined) => {
        if (newToken && newToken !== 'undefined' && newToken !== 'null') {
            setCookie(tokenKey, newToken)
        } else {
            removeCookie(tokenKey)
            setUser(null);
        }
    }

    const doToken = (useToken: OptionalCookie) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer '+useToken
        axios.get('/me', {
            data: {},
            baseURL: axios.defaults.baseURL,
            headers: {
                'Authorization': 'Bearer '+useToken,
                'ContentType': 'application/json',
                'Accept': 'application/json',
            }
        })
            .then(({ data }) => {
                setUser(data.data)
            })
            .catch((e) => {
                if ([routes.login, routes.twoFactorChallenge].indexOf(window.location.pathname) === -1) {
                    saveToken()
                    navigate(routes.login)
                }
            })
            .finally(() => {
                setLoading(false)
                setMounted(true);
            })
    }

    const loadCurrentUser = () => {
        doToken(getCookie(tokenKey));
    }

    useEffect(() => {
        loadCurrentUser()
    }, [])

    return (
        <AuthContext.Provider value={{
            user,
            saveToken,
            isLoading,
            reloadUser: loadCurrentUser,
            logout: () => saveToken(undefined),
        }}>
            {mounted ? children : null}
        </AuthContext.Provider>
    )
}

export default function useAuth() {
    return useContext(AuthContext)
}

