import React from 'react';
import useGet from "../hooks/useGet";
import {backendEndpoints} from "../utils";
import {UsagePolicy} from "../types/Models";

function UsagePolicySpan({ id }: { id: string }) {
    const { data } = useGet<Array<UsagePolicy>>({ endpoint: backendEndpoints.usagePolicy() });

    return <>{data?.find((record) => record.value == parseInt(id))?.label}</>;
}

export default UsagePolicySpan;
