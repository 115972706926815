import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import useGet from "../hooks/useGet";
import {FinancialCategoryModel} from "../types/Models";
import {backendEndpoints} from "../utils";
import {OverlayTrigger} from "react-bootstrap";

export default function FinancialCategorySpan({ costTypeId }: { costTypeId: number }) {
    const { data } = useGet<FinancialCategoryModel[]>({
        endpoint: backendEndpoints.financialCategories(),
    });

    const result = data?.find(entry => entry.cost_type_id == costTypeId)

    return (
        <div>
            {result?.name}
            &nbsp;
            <span className="gray-600">- ID {costTypeId}</span>
        </div>
    );
}
