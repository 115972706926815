import {EmployeeModel, EmployeeProjectModel, ProjectModel} from "../../types/Models";
import {useField} from "formik";
import useGet from "../../hooks/useGet";
import {backendEndpoints, concepts} from "../../utils";
import {Form} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {Plus, Trash} from "react-bootstrap-icons";
import {FloatingLabelField, Select} from "../forms";
import React from "react";

function getEmptyProjectAssignment() : EmployeeProjectModel {
    return {
        project_id: '',
        employee_id: '',
        start_date: '',
        end_date: '',
        fte: 1.000,
        salary: null,
        job_title: '',
    };
}

export default function EmployeeProjects({ name }: { name: string }) {
    const [{ value: projects }, , { setValue }] = useField<(Pick<ProjectModel, 'id'> & { pivot: EmployeeProjectModel })[]>(name);
    const { data: allProjects } = useGet<ProjectModel[]>({
        endpoint: backendEndpoints.projects(),
    });
    const { data: allEmployees } = useGet<EmployeeModel[]>({
        endpoint: backendEndpoints.employees(),
    });
    const labelSize = 2;

    return (
        <Form.Group as={Row}>
            <Form.Label column sm={labelSize}>
                {name === 'projects' ? concepts.Projects : concepts.Employees}
            </Form.Label>
            <Col sm={12 - labelSize}>
                {projects?.map((project, idx) => {
                    const inputPrefix = `${name}.${idx}.pivot`;

                    return (
                        <Row className="d-flex align-items-center" idx={idx}>
                            <Col style={{ flex: '0 0 50px' }}>
                                <Form.Label sm={labelSize}>&nbsp;</Form.Label>
                                <Button>
                                    <Trash
                                        onClick={(e) => {
                                            e.preventDefault();
                                            projects.splice(idx);
                                            setValue(projects);
                                        }}
                                    />
                                </Button>
                            </Col>
                            <Col>
                                {name === 'employees' ? (
                                    <Select
                                        valueKey="value"
                                        options={allEmployees?.map(({ id, name }) => ({ value: id, label: name || `Employee ID ${id}` })) || []}
                                        name={`${inputPrefix}.employee_id`}
                                        label="Employee"
                                        labelWidth={0}
                                        placeholder="Select"
                                        required
                                    />
                                ) : (
                                    <Select
                                        valueKey="value"
                                        options={allProjects?.map(({ id, name }) => ({ value: id, label: name || `Project ID ${id}` })) || []}
                                        name={`${inputPrefix}.project_id`}
                                        label="Project"
                                        labelWidth={0}
                                        placeholder="Select"
                                        required
                                    />
                                )}
                            </Col>
                            <Col>
                                <FloatingLabelField required name={`${inputPrefix}.start_date`} label="Employee start date" labelSize={0} type="date" />
                            </Col>
                            <Col>
                                <FloatingLabelField name={`${inputPrefix}.end_date`} label="Employee end date" labelSize={0} type="date" />
                            </Col>
                            <Col>
                                <FloatingLabelField name={`${inputPrefix}.job_title`} label="Job title" labelSize={0} />
                            </Col>
                            <Col style={{ flex: '0 0 150px' }}>
                                <FloatingLabelField required name={`${inputPrefix}.salary`} label="Fulltime salary" labelSize={0} />
                            </Col>
                            <Col style={{ flex: '0 0 150px' }}>
                                <FloatingLabelField required name={`${inputPrefix}.fte`} label="FTE at project" labelSize={0} />
                            </Col>
                        </Row>
                    )
                })}
                <Button
                    variant="light"
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        setValue(projects.concat([{ id: 0, pivot: getEmptyProjectAssignment() }]));
                    }}
                >
                    <Plus />
                    &nbsp;New project assignment
                </Button>
            </Col>
        </Form.Group>
    );
}
