import React from 'react';
import {backendEndpoints, concepts, routes} from "../../utils";
import useAuth from "../../hooks/useAuth";
import {CheckLg} from "react-bootstrap-icons";
import useGet from "../../hooks/useGet";
import {Badge} from "react-bootstrap";

const Done = () => <Badge className="bg-success text-light ms-3"><CheckLg className="me-1" />Done</Badge>;

function AboutTheApp() {
    const { user } = useAuth();
    const { data } = useGet({ endpoint: backendEndpoints.grants() });

    return (
        <>
            <h1 className="my-4 text-primary fw-light">
                Welcome to the LUMC {concepts.AppName}!
            </h1>
            <p className="text-lg">
                We have created this application for you to make being
                <br />
                compliant with Grant specifications a lot easier.
            </p>

            <h3 className="gray-700 fw-light mt-5 mb-3">How it works</h3>
            <ol className="text-lg d-flex flex-column gap-3 ">
                <li>
                    Go through the&nbsp;
                    <a href={routes.uploadProcess} className="text-primary text-decoration-none">{concepts.UploadProcess}</a>
                    {user?.upload_process?.step_number === 4 && <Done />}
                </li>
                <li>
                    Add your&nbsp;
                    <a href={routes.grants} className="text-primary text-decoration-none">{concepts.Grants}</a>
                    {Array.isArray(data) && data?.length > 0 && <Done />}

                    <small className="d-block max-width-600 gray-700">
                        Information such as the total monetary amount or the grant categories
                        (e.g. Materials, Equipment, etc.)
                    </small>
                </li>
                <li>
                    <div>
                        Add&nbsp;
                        <a href={routes.projects} className="text-primary text-decoration-none">{concepts.Projects}</a>
                        &nbsp;to your {concepts.Grants}.
                    </div>
                    <small className="d-block max-width-600 gray-700">
                        Projects are found in the data and need to be linked to a grant.
                        By combining the projects' start and end date with
                        the {concepts.Grant} budget, we can create an
                        insightful budgetting {concepts.UserDashboard}.
                    </small>
                </li>
                <li>
                    See the insights on your&nbsp;
                    <a href={routes.dashboard} className="text-primary text-decoration-none">{concepts.UserDashboard}</a>
                </li>
                <li>That's it!</li>
            </ol>
        </>
    );
}

export default AboutTheApp;
