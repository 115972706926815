import React, {useEffect, useRef, useState} from 'react';
import axios, {AxiosError, CancelToken, CancelTokenSource} from "axios";
import useAuth from "../../hooks/useAuth";
import {useNavigate} from "react-router";
import PageHeader from "../../components/layout/page-header/PageHeader";
import Description from "../../components/Description";
import Spinner from "react-bootstrap/Spinner";
import {Alert} from "react-bootstrap";
import {ApiServerResponse} from "../../types/Api";
import _ from 'lodash';
import useGet from "../../hooks/useGet";
import {backendEndpoints, routes} from "../../utils";
import {FileModel, UploadProcessModel} from "../../types/Models";
import FormatError, {ErrorData} from "./FormatError";
import DeleteUploadProcessButton from "../../components/upload-process/DeleteUploadProcessButton";

interface NextData {
    file_id: number
    row_index: number
}

type FileId = string;
type RowIndex = string;
type CellIndex = string;

type ValidationErrors = Record<FileId, Record<RowIndex, Record<CellIndex, ErrorData>>>

function ValidateFormatPage() {
    const { user, reloadUser } = useAuth();
    const navigate = useNavigate();
    const ref = useRef<{ batchNumber: number, rowIndex: number }>({ batchNumber: 0, rowIndex: 0 })
    const [batchNumber, setBatchNumber] = useState(0);
    const [fileIdInProcess, setFileIdInProcess] = useState(0);
    const [error, setError] = useState('');
    const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
    const [isValidating, setValidating] = useState(true);
    const [speedInSeconds, setSpeed] = useState(0);

    const { data: uploadProcessWithFormatErrors } = useGet<UploadProcessModel>({ endpoint: user?.upload_process?.id ? backendEndpoints.uploadProcess(user?.upload_process?.id)+'?_with[]=files.format_errors': '' });
    const { data: file } = useGet<FileModel>({ endpoint: fileIdInProcess ? backendEndpoints.file(fileIdInProcess): '' });

    useEffect(() => {
        if (uploadProcessWithFormatErrors?.files) {
            const o: ValidationErrors = {};
            for (let i = 0; i < uploadProcessWithFormatErrors.files.length; i++) {
                const fileWithFormatErrors = uploadProcessWithFormatErrors.files[i];
                for (let j = 0; j < fileWithFormatErrors.format_errors.length; j++) {
                    const {file_id, row_index, cell_index, row, message, id } = fileWithFormatErrors.format_errors[j];

                    if (!o.hasOwnProperty(file_id)) o[file_id] = {}
                    if (!o[file_id].hasOwnProperty(row_index)) o[file_id][row_index] = {}

                    o[file_id][row_index][cell_index] = { row, message, id };
                }
            }
            setValidationErrors(_.merge(validationErrors, o));
        }
    }, [uploadProcessWithFormatErrors]);

    const nextStep = () => {
        axios.put(backendEndpoints.uploadProcess(user?.upload_process?.id)+'?_method=PUT', { step_number: 3 })
            .then(() => {
                reloadUser();
                navigate(routes.analysis);
            });
    }

    const doValidation = (token: CancelTokenSource, next?: NextData | undefined) => {
        ref.current.batchNumber += 1;
        if (next?.row_index) {
            ref.current.rowIndex = next.row_index;
        }
        if (next?.file_id) {
            setFileIdInProcess(next.file_id);
        }
        setBatchNumber(ref.current.batchNumber);
        setValidating(true);
        setError('');

        axios.get<ApiServerResponse<{ next: NextData }, ValidationErrors>>(`/upload-processes/${user?.upload_process?.id}/validate-format`, { params: next, cancelToken: token.token })
            .then((resp) => {
                setValidating(false);
                console.log(resp, validationErrors);
                if (resp.status === 200 && Object.keys(validationErrors).length === 0) {
                    nextStep();
                }
            })
            .catch((e) => {
                const message = e.response?.data?.message || e.message
                if (message !== 'canceled' && message !== 'Processing...') {
                    setError(message);
                }
                if (e.response?.status == 307) {
                    const newNext = e.response.data?.data?.next as NextData; // TODO Remove 'as', but fix typing in axios.get
                    if (_.isEqual(newNext, next)) {
                        throw new Error('Something went wrong');
                    }
                    const errors = e.response.data?.errors;
                    if (typeof errors === 'object' && !Array.isArray(errors)) {
                        setValidationErrors(_.merge(validationErrors, errors));
                    }
                    if (newNext) {
                        doValidation(token, newNext);
                    }
                } else {
                    setValidating(false);
                }
            })
            .finally(() => {

            })
    };

    useEffect(() => {
        const token = axios.CancelToken.source();
        const stepNumber = user?.upload_process?.step_number;
        if (stepNumber == 1) {
            navigate(routes.uploadProcess);
        } else if (stepNumber == 2) {
            ref.current.batchNumber = 0;
            ref.current.rowIndex = 0;
            doValidation(
                token,
                user?.upload_process?.validation_next_file_id && user?.upload_process?.validation_next_row_index ? {
                    row_index: user.upload_process.validation_next_row_index,
                    file_id: user.upload_process.validation_next_file_id,
                } : undefined
                );
        } else if (stepNumber == 3) {
            navigate(routes.analysis);
        }
        return () => {
            token.cancel()
        }
    }, [user?.upload_process?.step_number])
    let unresolvedTotal = 0;
    let unresolvedRendered = 0;
    const rows = Object.keys(validationErrors).map((fileId) => {
        return Object.keys(validationErrors[fileId]).map((rowId) => {
            return Object.keys(validationErrors[fileId][rowId]).map((cellIdx) => {
                const formatError: ErrorData = validationErrors[fileId][rowId][cellIdx];
                // TODO add resolved column to FormatErrorModel
                const unresolved = !formatError.replacement;
                if (unresolved) {
                    unresolvedTotal += 1;
                }
                const shouldRender = unresolved && unresolvedTotal < 10;
                if (shouldRender) {
                    unresolvedRendered += 1;
                }
                return shouldRender ? (
                    <FormatError
                        fileId={fileId}
                        cellIdx={cellIdx}
                        formatError={validationErrors[fileId][rowId][cellIdx]}
                        key={`${fileId}-${rowId}-${cellIdx}`}
                    />
                ) : null;
            });
        })
    });
    return (
        <>
            <PageHeader header="Upload Process: Validating formatting" />
            <Description>Before we analyse the data from all sheets, the system first checks if each cell in each column has the right format.</Description>
            {error && <Alert variant="danger">{error}</Alert>}
            {isValidating && (
                <>
                    <Description>
                        <strong>This can take a while...</strong>
                        <br />
                        <span>
                            Please do not close this tab. If the tab closes, the process will stop. But when you
                            come back, it will continue where it left off last time.
                        </span>
                    </Description>
                    <div className="d-flex gap-2 align-items-center">
                        <Spinner animation="border" size="sm" />
                        <Description className="mb-0">
                            <span>Now at row {String(ref.current.rowIndex)} in file <b>{file?.name}</b></span>
                        </Description>
                    </div>
                </>
            )}
            <h2>Total formatting errors left: {unresolvedTotal}</h2>
            {rows}
            <DeleteUploadProcessButton />
        </>
    );
}

export default ValidateFormatPage;
