import React from 'react';
import useGet from "../../hooks/useGet";
import { UnassignedFinCategoryTask } from "../../pages/upload-process/Task";
import { backendEndpoints } from "../../utils";

const sortByName = (a: UnassignedFinCategoryTask, b: UnassignedFinCategoryTask) => {
    if (a.financial_category.name > b.financial_category.name) return 1;
    if (a.financial_category.name < b.financial_category.name) return -1;
    return 0;
};

export default function useUnassignedCategories({ grantId }: { grantId?: number | string }) {
    const { data: tasks, isLoading, error } = useGet<UnassignedFinCategoryTask[]>({
        endpoint: grantId ? backendEndpoints.grantUnassignedCategories(grantId) : '',
        params: {
            _with: ['grant_categories.financial_categories']
        }
    });
    return {
        tasks: Array.isArray(tasks) ? tasks.sort(sortByName) : tasks,
        isLoading,
        error,
    }
}
