import React, {useState} from 'react';
import {Card} from "react-bootstrap";
import {backendEndpoints, concepts} from "../../utils";
import {ArrowRight, PlusLg} from "react-bootstrap-icons";
import SelectBackend from "../../components/forms/SelectBackend";
import Description from "../../components/Description";
import {OpenQuickGrantCategoryProps, useQuickNewGrantCategoryContext} from "./QuickAddNewGrantCategoryButton";
import Button from "react-bootstrap/Button";
import {useField} from "formik";
import {reloadEverywhere} from "../../hooks/useReloadEverywhere";
import cx from "classnames";


function AddNewCategoryButton({ financialCategoryId, grantId, onSuccess }: OpenQuickGrantCategoryProps) {
    const { open } = useQuickNewGrantCategoryContext();
    return (
        <Button variant="link" onClick={() => open({ grantId, financialCategoryId, onSuccess })}>
            <PlusLg />
            &nbsp;new {concepts.GrantCategory}
        </Button>
    );
}

enum TaskType {
    UNASSIGNED_FIN_CAT_ID = 'unassigned-financial-category-id',
    EDIT = 'edit'
}
interface SharedTask {
    code: string,
    data: string,
    type: TaskType
}


export interface UnassignedFinCategoryTask extends SharedTask {
    type: TaskType.UNASSIGNED_FIN_CAT_ID,
    grant: {
        name: string,
        id: number,
    },
    project: {
        name: string,
        id: number
    },
    financial_category: {
        name: string,
        cost_category_name: string,
        id: number
    },
}

export interface EditTask extends SharedTask {
    type: TaskType.EDIT,
}

function Task({ task }: { task: UnassignedFinCategoryTask }) {
    const { grant, project, financial_category, code } = task;
    const name = `tasks.${code}`;
    const [, { error }, { setValue }] = useField(name);
    const endpoint = backendEndpoints.grantCategoriesOfGrant(grant.id);

    return (
        <Card className={cx({'mb-2': true, ['border-danger border']: error })}>
            <Card.Body>
                <Card.Title>
                    Please assign the {concepts.FinancialCategory} to {concepts.GrantCategory}:
                </Card.Title>
                <Card.Text>
                    <span className="d-flex align-items-center mb-2 gap-2 ">
                        <div className="min-width-250">
                            <b>{financial_category.name} ({financial_category.id})</b>
                            <div>{financial_category.cost_category_name}</div>
                        </div>
                        <span className="flex-grow-0 text-center text-xl">
                            <ArrowRight />
                        </span>
                        <div className="d-flex text-left align-items-center">
                            <SelectBackend
                                endpoint={endpoint}
                                label=""
                                labelWidth={0}
                                name={name}
                                hideIfNoOptions
                                placeholder="Optional"
                                forceDropdown
                            />
                            <div className="max-width-400">
                                <AddNewCategoryButton
                                    onSuccess={(grantCategory) => {
                                        reloadEverywhere(endpoint);
                                        setValue(grantCategory.id)
                                    }}
                                    financialCategoryId={financial_category.id}
                                    grantId={grant.id}
                                />
                            </div>
                        </div>
                    </span>
                    <Description className="text-sm mb-0">
                        <span>
                            In {concepts.Project}&nbsp;
                            <b>{project.name}</b>
                            &nbsp;to a {concepts.GrantCategory} of&nbsp;
                            <b>{grant.name}</b>
                        </span>
                    </Description>
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default Task;
