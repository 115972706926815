import { useQuery } from 'react-query'
import axios, {AxiosError, AxiosResponse} from 'axios'
import {UseQueryOptions} from "react-query/types/react/types";
import {ServerResponse} from "http";
import {ApiServerResponse} from "../types/Api";
import {useEffect, useState} from "react";
import {routes} from "../utils";
import useGet, {UseGetProps} from "./useGet";

/**
 *
 * @param cacheTime
 * @param endpoint
 * @param onSuccess
 * @param onError
 * @param params
 * @param dataKey In nearly all cases, the JSON response contains a data key which contains the data you need. However,
 *                it may be the case that you need another key.
 */
export default function usePaginatedGet<TData extends any[] | Record<string, any>>({ params, ...props } : UseGetProps<TData>) {
    const [pageIndex, setPageIndex] = useState(0);

    const getResults = useGet({ ...props, params: {...params, _limit: `${pageIndex},20` }});

    return {
        ...getResults,
        setPageIndex,
        pageIndex,
    }
}
