import React from 'react';
import styles from './Layout.module.scss';
import {concepts, routes} from "../../utils";
import LUMCLogo from './../../assets/images/logos/LUMC-logo.png';
import {Link} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import {Nav, NavDropdown} from "react-bootstrap";

interface LayoutProps {
    children: string | JSX.Element | JSX.Element[]
    mainOnly?: boolean
}

export default function Layout({ children, mainOnly }: LayoutProps) {
    const { user } = useAuth();

    return (
        <div className={`bg-white d-flex flex-column vw-100 vh-100 ${styles.Layout}`}>
            <div className="py-1 px-2 bg-white border-bottom d-flex align-items-center gap-3">
                <a href={routes.dashboard}>
                    <img src={LUMCLogo} height={50} />
                </a>
                <div className="border-left gray-700 flex-grow-1">
                    {concepts.AppName}
                </div>
                {user && (
                    <NavDropdown title={`Hi, ${user?.first_name}`}>
                        <NavDropdown.Item as="a" href={routes.accountEdit} className="text-center">
                            {concepts.Settings}
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item as="a" href={routes.logout} className="text-center text-danger">
                            Log out
                        </NavDropdown.Item>
                    </NavDropdown>
                )}
            </div>
            <div className="flex-grow-1">
                <div className="d-flex gap-4 h-100 w-100">
                    {!mainOnly && (
                        <div className={`bg-secondary ${styles.MenuRow}`}>
                            <Link className={styles.Link} to={routes.aboutTheApp}>{concepts.AboutTheApp}</Link>
                            <Link className={`${styles.Link}`} to={routes.uploadProcess}>{concepts.UploadProcess}</Link>
                            <Link className={styles.Link} to={routes.dashboard}>{concepts.UserDashboard}</Link>
                            <Link className={styles.Link} to={routes.grants}>{concepts.Grants}</Link>
                            <Link className={styles.Link} to={routes.grantCategories}>{concepts.GrantCategories}</Link>
                            <Link className={styles.Link} to={routes.financialCategories}>{concepts.FinancialCategories}</Link>
                            <Link className={styles.Link} to={routes.employees}>{concepts.Employees}</Link>
                            <Link className={styles.Link} to={routes.costs}>{concepts.CostTransactions}</Link>
                            <Link className={styles.Link} to={routes.projects}>{concepts.Projects}</Link>
                        </div>
                    )}
                    <div className={`d-flex flex-column align-items-stretch pt-2 pe-4 flex-grow-1 position-relative ${mainOnly ? styles.MainOnly : styles.MainWithMenu}`}>
                        <main className="flex-grow-1 w-100">{children}</main>
                        <footer className="gray-500 text-sm py-2 mt-4">
                            &copy; Leiden Universitair Centrum. Developed by <a href="mailto:giguru.scheuer@gmail.com">Scheuer Software</a>.
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    );
}
