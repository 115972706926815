import React, {useEffect, useMemo, useState} from 'react';
import * as yup from "yup";
import _ from "lodash";
import PageHeader from "../../components/layout/page-header/PageHeader";
import Description from "../../components/Description";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import {ApiServerResponse} from "../../types/Api";
import {backendEndpoints, concepts} from "../../utils";
import {Accordion, Alert} from "react-bootstrap";
import {BackendForm} from "../../components/forms";
import Task, {EditTask, UnassignedFinCategoryTask} from "./Task";
import {useNavigate} from "react-router";
import navigateToStep from "./navigateToStep";
import Loading from "../../components/Loading";
import ExistingRecordEditor from "../../components/analysis/ExistingRecordEditor";
import {EmployeeData, EmployeeProjectData, FinCategoryData, ProjectData} from "../../components/analysis/Models";
import FoundCategoriesAccordion from "../../components/analysis/FoundCategoriesAccordion";
import FoundProjectsAccordion from "../../components/analysis/FoundProjectsAccordion";
import {TransactionEntryModel} from "../../types/Models";
import DataTable from "../../components/data-table/DataTable";
import DeleteUploadProcessButton from "../../components/upload-process/DeleteUploadProcessButton";

function interleave<T>(xInput: T[], ys: T[] = []): T[] {
    const [ x, ...xs ] = xInput;
    return x === undefined ? ys : [x, ...interleave (ys, xs)];
}

function DisagreeingTransactions({ transactions }: { transactions: TransactionEntryModel[][] }) {
    const headers = Object.keys(transactions[0][0]) as Array<keyof TransactionEntryModel>;
    return (
        <>
            <DataTable
                headers={headers}
                rows={interleave(transactions[0], transactions[1]).map((row) => (
                    headers.map((h) => `${row[h] || ''}`)
                ))}
                showDottedRow={false}
            />
        </>
    )
}

interface AnalysisData {
    projects: {
        creating: Array<ProjectData>
        updating: Array<ProjectData>
    }
    employees: {
        creating: Array<EmployeeData>
        updating: Array<EmployeeData>
    }
    fin_categories: {
        creating: Array<FinCategoryData>,
        updating: Array<FinCategoryData>
    }
    employee_projects: {
        creating: Array<EmployeeProjectData>
        updating: Array<EmployeeProjectData>
    }
    tasks: Array<UnassignedFinCategoryTask | EditTask>
    transaction_entries: {
        comparison: {
            date_of_disagreement: string,
            disagreeing_transactions: Array<TransactionEntryModel[]>,
            number_of_entries_to_be_deleted: number,
            number_of_entries_to_be_inserted: number,
        }
    }
}

function Analysis({}) {
    const { user, reloadUser } = useAuth();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [analysisData, setAnalysisData] = useState<AnalysisData | null>(null);

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();

        if (user?.upload_process?.id) {
            setLoading(true);
            setError('');
            axios.get<ApiServerResponse<AnalysisData>>(`/upload-processes/${user?.upload_process?.id}/analyse`, { cancelToken: cancelToken.token })
                .then((resp) => {
                    setAnalysisData(resp.data.data);
                })
                .catch(e => {
                    if (!e.isAxiosError || e.message !== 'canceled') {
                        setError(e.response?.data?.message || e.message)
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        return () => {
            cancelToken.cancel();
        }
    }, [user?.upload_process?.id]);

    useEffect(() => {
        navigateToStep(navigate, user);
    }, [user]);

    const namelessProjects = analysisData?.projects.creating?.filter(
        (newProject) => newProject.name.length === 0
    );

    const initTasks = useMemo(() => {
        const o = {};
        analysisData?.tasks.forEach(({ code, data }) => {
            _.setWith(o, code, data || '', Object);
        });
        return o;
    }, [analysisData]);

    const finCategoryAssignmentTasks: UnassignedFinCategoryTask[] | undefined = analysisData?.tasks.filter(
        (task: UnassignedFinCategoryTask | EditTask) => task.type === 'unassigned-financial-category-id'
    ) as UnassignedFinCategoryTask[];

    return (
        <>
            <BackendForm
                initialValues={{
                    tasks: initTasks,
                }}
                enableReinitialize
                showFooter
                validationSchema={yup.object({

                })}
                targetMethod="POST"
                targetEndpoint={backendEndpoints.finalise(user?.upload_process?.id)}
                onSuccess={() => {
                    reloadUser();
                }}
            >
                <div className="p-3">
                    <PageHeader header="Upload Process: Analysis" />
                    <Description className="mb-5">
                        We have analyzed and compared the new data with the old data.
                        If there are any conflicts or incompletions, please resolve them.
                    </Description>
                    {isLoading && <Loading />}
                    {error && <Alert variant="danger">{error}</Alert>}
                    {analysisData?.projects.creating && (
                        <>
                            <h4 className="mt-3">{analysisData?.projects.creating?.length || 'No'} new projects found.</h4>
                            {Array.isArray(namelessProjects) && namelessProjects?.length > 0 && (
                                <p className="max-width-800">
                                    <b>{namelessProjects?.length} nameless projects</b>
                                    . This means we only have project IDs from the financial transactions.
                                    <br />
                                    If you upload more project data in the future, the project name will be automatically linked.
                                </p>
                            )}
                            {analysisData?.projects.creating?.length > 0 && <FoundProjectsAccordion records={analysisData?.projects.creating} />}
                        </>
                    )}
                    {analysisData?.projects.updating && (
                        <>
                            <h4 className="mt-3">{analysisData.projects.updating?.length || 'No'} existing projects found.</h4>
                            {analysisData.projects.updating.length === 0 && <p className="max-width-800">This means that the uploaded files contain no information on projects known in the {concepts.AppName}.</p>}
                            {Array.isArray(analysisData.projects.updating) && (
                                <ExistingRecordEditor
                                    originalRecords={analysisData.projects.updating}
                                    name="projects"
                                    indexKey="fin_sys_id"
                                />
                            )}
                        </>
                    )}

                    <div className="my-4">&nbsp;</div>
                    {analysisData?.fin_categories.creating && (
                        <>
                            <h4 className="mt-3">{analysisData?.fin_categories.creating?.length} new financial categories found.</h4>
                            {analysisData?.fin_categories.creating?.length > 0 && (
                                <>
                                    <p className="max-width-800">We simply show this for transparency. If you want you can verify the data.</p>
                                    <FoundCategoriesAccordion records={analysisData.fin_categories.creating} />
                                </>
                            )}
                        </>
                    )}
                    {analysisData?.fin_categories.updating && (
                        <>
                            <h4 className="mt-3">{analysisData.fin_categories.updating.length || 'No'} existing categories found.</h4>
                            {analysisData.fin_categories.updating.length === 0 && <p className="max-width-800">This means that the uploaded files contain no information on categories known in the {concepts.AppName}.</p>}
                            {Array.isArray(analysisData.fin_categories.updating) && (
                                <ExistingRecordEditor
                                    originalRecords={analysisData.fin_categories.updating}
                                    name="fin_categories"
                                    indexKey="cost_type_id"
                                />
                            )}
                        </>
                    )}
                    <div className="my-4">&nbsp;</div>
                    {analysisData?.employees.creating && (
                        <>
                            <h4 className="mt-3">{analysisData?.employees.creating?.length || 'No'} new employees found.</h4>
                            {analysisData?.employees.creating?.length > 0 && (
                                <Accordion>
                                    <Accordion.Item eventKey="all">
                                        <Accordion.Header>New employees.</Accordion.Header>
                                        <Accordion.Body>
                                            <table className="table table-sm">
                                                <thead>
                                                <tr>
                                                    <th>Employee ID</th>
                                                    <th>Name</th>
                                                    <th>Expected final employement date</th>
                                                    <th>Total hours</th>
                                                    <th>Total FTE</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {analysisData?.employees.creating?.filter((emp) => emp.full_name.length).map((emp) => (
                                                    <tr key={emp.external_id[0]}>
                                                        <td>{emp.external_id[0]}</td>
                                                        <td><div className="text-ellipsis">{emp.full_name[0]}</div></td>
                                                        <td>{emp.expected_final_employment_date.length ? emp.expected_final_employment_date[0] : null}</td>
                                                        <td>{emp.total_hours.length ? emp.total_hours[0] : null}</td>
                                                        <td>{emp.total_fte.length ? emp.total_fte[0] : null}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            )}
                        </>
                    )}
                    {analysisData?.employees.updating && (
                        <>
                            <h4 className="mt-3">{analysisData?.employees.updating?.length || 'No'} existing employees found.</h4>
                            {!analysisData?.employees.updating?.length && (
                                <p className="max-width-800">
                                    This means that the uploaded files contain no new information on employees
                                    already known in the {concepts.AppName}.
                                </p>
                            )}
                            {Array.isArray(analysisData.employees.updating) && (
                                <ExistingRecordEditor
                                    originalRecords={analysisData.employees.updating}
                                    name="employees"
                                    indexKey="external_id"
                                />
                            )}
                        </>
                    )}
                    <div className="my-4">&nbsp;</div>
                    {analysisData?.employee_projects.creating && (
                        <>
                            <h4 className="mt-3">
                                {analysisData?.employee_projects.creating?.length || 'No'}
                                &nbsp;new {concepts.EmployeeProject} found.
                            </h4>
                        </>
                    )}
                    {analysisData?.employee_projects.updating && (
                        <>
                            <h4 className="mt-3">{analysisData?.employee_projects.updating?.length || 'No'} existing {concepts.EmployeeProject} found.</h4>
                            {!analysisData?.employee_projects.updating?.length && (
                                <p className="max-width-800">
                                    This means that the uploaded files contain no new information on
                                    project assignments for employees already known in the {concepts.AppName}.
                                </p>
                            )}
                            {Array.isArray(analysisData.employee_projects.updating) && (
                                <ExistingRecordEditor
                                    originalRecords={analysisData.employee_projects.updating}
                                    name="employee_projects"
                                    indexKey={['employee_id', 'project_id']}
                                />
                            )}
                        </>
                    )}

                    {analysisData?.transaction_entries?.comparison && (
                        <>
                            <div className="my-4">&nbsp;</div>
                            <h4>Transactions</h4>
                            {analysisData?.transaction_entries?.comparison.number_of_entries_to_be_deleted > 0 ? (
                                <>
                                    <Alert variant="warning">Conflicts were found.</Alert>
                                    <p className="fw-bold">
                                        The uploaded transactions and the current database started disagreeing after&nbsp;
                                        {analysisData?.transaction_entries?.comparison.date_of_disagreement}.
                                        <br />
                                        All database transactions after this date will be
                                        deleted ({analysisData?.transaction_entries?.comparison.number_of_entries_to_be_deleted} transactions)
                                        and be replaced by {analysisData?.transaction_entries?.comparison.number_of_entries_to_be_inserted} new transactions.
                                    </p>
                                    <DisagreeingTransactions
                                        transactions={analysisData.transaction_entries.comparison.disagreeing_transactions}
                                    />
                                </>
                            ) : (
                                <Alert variant="success" className="p-2 bg-transparent">
                                    No disagreement with the current database were found.
                                </Alert>
                            )}
                        </>
                    )}

                    {finCategoryAssignmentTasks && (
                        <>
                            <div className="my-4">&nbsp;</div>
                            <h4 className="mt-3">{finCategoryAssignmentTasks.length || 'No'} assignments required.</h4>
                            <p className="max-width-800">
                                Newly found financial categories must be assignment to grant categories of the project.
                                If there are newly found categories, but no tasks, the projects are not assigned to any
                                grant, thus no assignment is required.
                                When you link a project to a grant, you will be prompted to assign the categories.
                            </p>
                            {finCategoryAssignmentTasks
                                .map((task, ) => task.type === 'unassigned-financial-category-id' && (
                                    <Task task={task} key={task.code} />
                                ))}
                        </>
                    )}
                </div>
            </BackendForm>
            <DeleteUploadProcessButton />
        </>
    );
}

export default Analysis;
