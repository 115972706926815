import React from 'react';
import { useParams } from 'react-router-dom';
import {Alert} from 'react-bootstrap';
import {BackendForm, FloatingLabelField} from "../components/forms";
import PageHeader from "../components/layout/page-header/PageHeader";
import SelectBackend from "../components/forms/SelectBackend";
import {projectSchema} from "../utils/validationSchemas";
import {backendEndpoints, concepts, routes} from "../utils";
import Loading from "../components/Loading";
import {isNew} from "../components/forms/FormHelpers";
import useGet from "../hooks/useGet";
import {CheckboxBackendProvider} from "../components/forms/checkbox/CheckboxHelpers";
import {
    GrantModel,
    GroupModel,
    ProjectModel,
} from "../types/Models";
import AccordionOverview from "../components/transaction-entries/AccordionOverview";
import EmployeeProjects from "../components/employee-projects/EmployeeProjects";

const Fields = {
    StartDate: () => <FloatingLabelField label="Start date" type="date" name="start_date" />,
    EndDate: () => <FloatingLabelField label="End date" type="date" name="end_date" />,
    FinancialNumberId: () => <FloatingLabelField label="Fin. Admin. Project ID" name="financial_number_id" />,
    ExternalID: () => <FloatingLabelField label="PeopleSoft Project ID" name="external_id" />,
    Name: () => <FloatingLabelField label="Name" name="name" />,
    NameShort: () => <FloatingLabelField label="Abbreviation" name="name_short" />,
    Group: () => <SelectBackend<GroupModel> endpoint="groups" name="group_id" label="Group"  />,
};

function Project({}) {
    const { id } = useParams();
    const withParams = '?_with[]=employees';
    const { data: existingProject, isLoading, error } = useGet<ProjectModel>({
        endpoint: isNew(id) ? '' : backendEndpoints.project(id),
        params: {
            _with: ['transaction_entries', 'employees']
        }
    });

    if (error) return <Alert>{error}</Alert>;

    return (
        <CheckboxBackendProvider>
            {isLoading ? <Loading /> : (
                <BackendForm
                    targetEndpoint={isNew(id) ? 'projects'+withParams : `projects/${id}${withParams}`}
                    targetMethod={isNew(id) ? 'POST' : 'PUT'}
                    initialValues={existingProject || {
                        name: '',
                        name_short: '',
                        start_date: '',
                        end_date: '',
                        financial_number_id: '',
                        external_id: '',
                        grant_id: '',
                        employees: [],
                    }}
                    showFooter
                    validationSchema={projectSchema}
                    onSuccess={({ data }) => {
                        if (isNew(id)) {
                            window.location.href = routes.project(data.data.id);
                        }
                    }}
                >
                    <PageHeader
                        header={isNew(id) ? `Add ${concepts.Project}` : `Edit ${concepts.Project}`}
                        returnButton={{
                            text: `Back to ${concepts.Projects}`,
                            url: routes.projects,
                        }}
                    />
                    <Fields.Group />
                    <Fields.Name />
                    <Fields.NameShort />
                    <Fields.StartDate />
                    <Fields.EndDate />
                    <Fields.FinancialNumberId />
                    <Fields.ExternalID />

                    <SelectBackend<GrantModel>
                        endpoint={backendEndpoints.grants()}
                        name="grant_id"
                        label="Grants"
                        placeholder="-- No grant --"
                    />
                    <EmployeeProjects name="employees" />
                    <span>
                        {existingProject?.financial_number_id
                            ? <AccordionOverview queryParams={{ financial_number_id: existingProject.financial_number_id }} />
                            : null}
                    </span>
                </BackendForm>
            )}
        </CheckboxBackendProvider>
    );
}

export default Project;
