import React from 'react';
import {useField} from "formik";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams } from 'react-router-dom';
import {Alert, Form} from 'react-bootstrap';
import {BackendForm, FloatingLabelField, Select} from "../components/forms";
import PageHeader from "../components/layout/page-header/PageHeader";
import SelectBackend from "../components/forms/SelectBackend";
import {employeeSchema} from "../utils/validationSchemas";
import {backendEndpoints, concepts, routes} from "../utils";
import Loading from "../components/Loading";
import {isNew} from "../components/forms/FormHelpers";
import useGet from "../hooks/useGet";
import {CheckboxBackendProvider} from "../components/forms/checkbox/CheckboxHelpers";
import {
    EmployeeProjectModel,
    GroupModel,
    ProjectModel,
    EmployeeModel,
} from "../types/Models";
import EmployeeProjects from "../components/employee-projects/EmployeeProjects";

const Fields = {
    ExternalID: () => <FloatingLabelField label="PeopleSoft ID" name="external_id" />,
    Name: () => <FloatingLabelField label="Name" name="name" />,
    TotalFTE: () => <FloatingLabelField label="Total FTE" name="total_fte" />,
    TotalHours: () => <FloatingLabelField label="Total Hours" name="total_hours" />,
    EmploymentDate: () => <FloatingLabelField label="Expected final employment" name="expected_final_employment_date" />,
    Group: () => <SelectBackend<GroupModel> endpoint="groups" name="group_id" label="Group"  />,
};

type EmployeeWithEmployeeProjectsPivot = Omit<EmployeeModel, 'projects'> & {
    projects: Array<ProjectModel & { pivot: EmployeeProjectModel }>
}

    function Employee({}) {
    const { id } = useParams();
    const withParams = '?_with[]=projects';
    const { data: existingEmployee, isLoading, error } = useGet<EmployeeWithEmployeeProjectsPivot>({
        endpoint: isNew(id) ? '' : backendEndpoints.employee(id),
        params: {
            _with: ['projects']
        }
    });

    if (error) return <Alert>{error}</Alert>;

    return (
        <CheckboxBackendProvider>
            {isLoading ? <Loading /> : (
                <BackendForm
                    targetEndpoint={isNew(id) ? 'employees'+withParams : `employees/${id}${withParams}`}
                    targetMethod={isNew(id) ? 'POST' : 'PUT'}
                    initialValues={existingEmployee || {
                        name: '',
                        total_fte: '',
                        total_hours: '',
                        expected_final_employment_date: '',
                        group_id: '',
                        external_id: '',
                        projects: []
                    }}
                    showFooter
                    validationSchema={employeeSchema}
                    onSuccess={({ data }) => {
                        if (isNew(id)) {
                            window.location.href = routes.employee(data.data.id);
                        }
                    }}
                >
                    <PageHeader
                        header={isNew(id) ? `Add ${concepts.Employee}` : `Edit ${concepts.Employee}`}
                        returnButton={{
                            text: `Back to ${concepts.Employees}`,
                            url: routes.employees,
                        }}
                    />
                    <Fields.Group />
                    <Fields.Name />
                    <Fields.TotalFTE />
                    <Fields.TotalHours />
                    <Fields.EmploymentDate />
                    <Fields.ExternalID />
                    <EmployeeProjects name="projects" />
                </BackendForm>
            )}
        </CheckboxBackendProvider>
    );
}

export default Employee;
