
export const concepts = {
    AppName: 'Grant Manager',
    AboutTheApp: 'About the app',
    UserDashboard: 'Dashboard',
    Account: 'Account',
    Grants: 'Grants',
    FinancialCategories: 'Fin. Dept. Categories',
    FinancialCategory: 'Fin. Dept. Category',
    GrantCategories: 'Grant Categories',
    GrantCategory: 'Grant Category',
    Employees: 'Employees',
    Employee: 'Employee',
    EmployeeProject: 'Employee-Project assignment',
    CostTransaction: 'Transactie',
    CostTransactions: 'Transactions',
    Projects: 'Projects',
    Project: 'Project',
    Grant: 'Grant',
    Group: 'Group',
    Groups: 'Groups',
    GroupName: 'Group name',
    Username: 'Username',
    UploadProcess: 'Upload Process',
    DashboardStatCategory: 'Budget category',
    Settings: 'Settings',
}
