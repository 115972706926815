function mapTree(fd: FormData, key: string, value: any) {
    if (Array.isArray(value)) {
        if (value.length > 0) {
            for (let a = 0; a < value.length; a += 1) {
                mapTree(fd, `${key}[${a}]`, value[a]);
            }
        } else {
            fd.append(`${key}[]`, '');
        }
    } else if (typeof value === 'object' && value != null) {
        for (const prop in value)  {
            if (value.hasOwnProperty(prop)) {
                mapTree(fd, `${key}[${prop}]`, value[prop]);
            }
        }
    } else {
        fd.append(key, value === null ? '' : value);
    }
}

export function objectToFormData(values: Record<string, any>) : FormData {
    const fd = new FormData();
    const keys = Object.keys(values);
    for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        const value = values[key];
        mapTree(fd, key, value);
    }
    return fd;
}
