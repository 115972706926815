import React from 'react';
import {concepts} from "../utils";
import PageHeader from "../components/layout/page-header/PageHeader";

function Settings() {
    return (
        <>
            <PageHeader header={concepts.Settings} />
        </>
    );
}

export default Settings;
