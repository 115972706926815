import React from 'react';
import Button from "react-bootstrap/Button";
import {PlusLg} from "react-bootstrap-icons";
import {ButtonProps as BaseButtonProps} from "@restart/ui/Button";

interface AddButtonProps {
    onClick: BaseButtonProps['onClick']
}

export default function AddButton({ onClick } : AddButtonProps) {
    return (
        <Button variant="light" className="rounded-1" onClick={onClick}>
            <PlusLg />
            Add
        </Button>
    );
}

