import React from 'react';
import useGet from "../hooks/useGet";
import {backendEndpoints, concepts, routes} from "../utils";
import Table from "../components/layout/table/Table";
import {Column} from "react-table";
import {Alert} from "react-bootstrap";
import Loading from "../components/Loading";
import PageHeader from "../components/layout/page-header/PageHeader";
import usePaginatedGet from "../hooks/usePaginatedGet";
import PaginationBar from "../components/layout/PaginationBar";

const columns: Column[] = [
    { accessor: 'cost_type_id', Header: 'Cost Type ID' },
    { accessor: 'name', Header: 'Name' },
    { accessor: 'cost_category_name', Header: 'Category name' },
    { accessor: 'id', Header: 'ID' },
];

export default function FinancialCategories() {
    const { data: categories, error, isLoading, pagination, pageIndex, setPageIndex } = usePaginatedGet({
        endpoint: backendEndpoints.financialCategories()+'?_with[]=grant_categories'
    });

    return (
        <>
            <PageHeader header={concepts.FinancialCategories} />
            {pagination && <PaginationBar pageIndex={pageIndex} setPageIndex={setPageIndex} pagination={pagination} />}

            {isLoading && <Loading />}
            {error && <Alert variant="danger">{error}</Alert>}
            <Table
                memoisedData={Array.isArray(categories) ? categories : []}
                memoisedColumns={columns}
                buildLink={({ id }) => routes.financialCategory(id)}
                maxHeight={0}
            />
        </>
    );
}
