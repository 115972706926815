import React from 'react';
import useGet from "../../hooks/useGet";
import {backendEndpoints} from "../../utils";
import DataTable from "../../components/data-table/DataTable";


function FileSample({ fileId }: { fileId: number }) {
    const { data: sampledata } = useGet<{ headers: ({ label: string })[], sample_rows: string[][] }>({
        endpoint: backendEndpoints.fileSample(fileId),
    });

    return sampledata && 'sample_rows' in sampledata ? (
        <DataTable
            headers={sampledata.headers.map(({ label }) => label)}
            rows={sampledata.sample_rows}
            showDottedRow
        />
    ) : null;
}

export default FileSample;
