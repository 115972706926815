import React, {useContext} from "react";
import {Form} from "react-bootstrap";
import {PlusLg} from "react-bootstrap-icons";
import {CheckboxBackendContext, NewCheckboxProps} from "./CheckboxHelpers";

export function CheckboxNewOptionModal({ openNew, setOpenNew, optionModal: OptionModal, inPlaceModal }: { openNew: boolean, setOpenNew: (open: boolean) => void} & Pick<NewCheckboxProps, 'optionModal' | 'inPlaceModal'>) {
    const { setModalOutsideForm } = useContext(CheckboxBackendContext);

    return (
        <>
            <Form.Check type="checkbox">
                <button
                    className="bg-transparent border-0"
                    onClick={(e) => {

                        if (inPlaceModal) {
                            setOpenNew(true)
                        } else if (typeof OptionModal === 'function') {
                            setModalOutsideForm(<OptionModal close={() => setModalOutsideForm(undefined)} />);
                        }

                        e.preventDefault();
                    }}
                >
                    <PlusLg />
                    <Form.Check.Label>new</Form.Check.Label>
                </button>
            </Form.Check>
            {openNew && OptionModal && <OptionModal close={() => setOpenNew(false)} />}
        </>
    )
}
