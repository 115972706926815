import React from 'react';
import {Accordion} from "react-bootstrap";
import {ProjectData} from "./Models";

function FoundProjectsAccordion({ records }: { records: ProjectData[] }) {
    return (
        <Accordion>
            <Accordion.Item eventKey="all">
                <Accordion.Header>New named projects.</Accordion.Header>
                <Accordion.Body>
                    <table className="table table-sm">
                        <thead>
                        <tr>
                            <th>Project ID</th>
                            <th>Project Name</th>
                            <th>Start date</th>
                            <th>End date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {records?.filter((newProject) => newProject.name.length).map((newProject) => (
                            <tr key={newProject.fin_sys_id[0]}>
                                <td>{newProject.project_id.length ? newProject.project_id[0] : null}</td>
                                <td><div className="text-ellipsis">{newProject.name[0]}</div></td>
                                <td>{newProject.start_date.length ? newProject.start_date[0] : null}</td>
                                <td>{newProject.end_date.length ? newProject.end_date[0] : null}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default FoundProjectsAccordion;
