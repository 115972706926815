import React, {useState} from 'react';
import {ErrorMessage, useField} from "formik";
import cx from "classnames";
import {Form} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EmptyTexts from "../../layout/EmptyTexts";
import {CheckboxProps, NewCheckboxProps, NewOption, BackendOption, CheckboxPropsOption} from "./CheckboxHelpers";
import useGet from "../../../hooks/useGet";
import {CheckboxNewOptionModal} from "./CheckboxNewOptionModal";
import {findIndex} from "lodash";


export default function Checkboxes({
    options,
    name,
    label,
    onChange,
    optionExtraContent: ExtraContent,
    optionModal: OptionModal,
    emptyText,
    inPlaceModal,
    sorter = (a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
    },
    setValueModifier,
}: NewCheckboxProps<CheckboxPropsOption> & CheckboxProps) {
    const [openNew, setOpenNew] = useState<boolean>(false);
    const [openExisting, setExisting] = useState<typeof options[0] | null>(null);
    const [{ value: items }, , { setValue: setItems }] = useField<Array<NewOption | BackendOption | string | number | CheckboxPropsOption['data']>>(name);

    const newItems: NewOption[] = []
    items?.forEach((item) => {
        if (typeof item === 'object' && !('id' in item)) {
            newItems.push(item as NewOption);
        }
    });

    const optionOriginalIndex: Record<string, number> = {};
    options.forEach((o, idx) => {
        optionOriginalIndex[o.value] = idx
    });

    return (
        <Form.Group as={Row} className="mb-3" controlId={label}>
            <Form.Label column sm={2}>{label}</Form.Label>
            <Col sm={10}>
                {Array.isArray(options) && options.length === 0 && emptyText && <EmptyTexts>{emptyText}</EmptyTexts>}
                {options.sort(sorter).map((o) => {
                    const inputId = `checkbox-${name}-${o.value}`;
                    const itemIdx = items?.findIndex(item => typeof item === 'object' ? ('id' in item && item?.id === o.value) : item === o.value);
                    const checked = itemIdx > -1;

                    return (
                        <Form.Check type="checkbox" id={inputId} className={cx({ 'disabled': o.disabled })}>
                            <Form.Check.Input
                                type="checkbox"
                                name={name}
                                onChange={(e) => {
                                    if (checked) {
                                        const newItems = [...items]
                                        newItems.splice(itemIdx, 1);
                                        setItems(newItems)
                                    } else {
                                        setItems((items || []).concat(setValueModifier ? [setValueModifier(o)] : [o.value]))
                                    }
                                }}
                                checked={checked}
                                value={o.value}
                                disabled={o.disabled}
                            />
                            <Form.Check.Label htmlFor={inputId}>
                                {o.label}&nbsp;
                            </Form.Check.Label>
                            {OptionModal && (
                                <button
                                    className="link-primary p-0 bg-transparent border-0"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setExisting(o)
                                    }}
                                >
                                    edit
                                </button>
                            )}
                            {ExtraContent && <ExtraContent option={o} index={itemIdx} />}
                        </Form.Check>
                    )
                })}

                {newItems.map((item, idx) => {
                    const inputId = `checkbox-${name}-${idx}`;
                    const selectedIdx = items.findIndex(i => item === i);
                    return (
                        <Form.Check type="checkbox" id={inputId}>
                            <Form.Check.Input
                                type="checkbox"
                                name={name}
                                checked={selectedIdx > -1}
                                onChange={() => setItems(selectedIdx > -1 ? items.filter((i, idx) => idx != selectedIdx) : items.concat([item]))}
                            />
                            <Form.Check.Label htmlFor={inputId}>{item.name}</Form.Check.Label>
                            {ExtraContent && <ExtraContent option={item} index={options.length + idx} />}
                        </Form.Check>
                    )
                })}

                <ErrorMessage name={name} className="text-danger" component="span" />
                {OptionModal && (
                    <CheckboxNewOptionModal
                        openNew={openNew}
                        setOpenNew={setOpenNew}
                        optionModal={OptionModal}
                        inPlaceModal={inPlaceModal}
                    />
                )}
                {OptionModal && openExisting && (
                    <CheckboxNewOptionModal
                        openNew={openNew}
                        setOpenNew={setOpenNew}
                        optionModal={OptionModal}
                        inPlaceModal={inPlaceModal}
                    />
                )}
                {openExisting && OptionModal && <OptionModal selectedItem={openExisting} close={() => setExisting(null)} />}
            </Col>
        </Form.Group>
    );
}

type Props = NewCheckboxProps & Omit<CheckboxProps, 'options'> & {
    endpoint: string,
}

function CheckboxesBackend<SingleEntry extends { id: number, name: string }>({ endpoint, name, label, onChange, optionExtraContent, optionModal, emptyText, inPlaceModal, sorter }: Props) {
    const { data: options } = useGet<Array<SingleEntry>>({ endpoint });

    return (
        <Checkboxes
            options={Array.isArray(options) ? options.map(o => ({ value: o.id, label: o.name, data: o })) : []}
            label={label}
            name={name}
            inPlaceModal={inPlaceModal}
            emptyText={emptyText}
            onChange={onChange}
            optionExtraContent={optionExtraContent}
            optionModal={optionModal}
            sorter={sorter}
        />
    );
}


Checkboxes.Backend = CheckboxesBackend;
