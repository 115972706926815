
const userPrefix = '/user'

export const sectionIds = {
    personalInfo: 'personalInfo',
    subscriptions: 'subscriptions',
    teams: 'teams',
    security: 'security',
}

export const routes = {
    // START: before login routes
    login: '/login',
    aboutTheApp: '/about-the-app',
    signUp: '/sign-up',
    logout: '/logout',
    home: '/',
    download: '/download-photo51',
    employees: '/employees',
    employee: (id) =>  `/employees/${id}`,
    projects: '/projects',
    project: (id) =>  `/projects/${id}`,
    costs: '/costs',
    cost: (id) => `/costs/${id}`,
    grants: '/grants',
    grant: (id) => `/grants/${id}`,
    grantCategories: '/grant-categories',
    grantCategory: (id) => `/grant-categories/${id}`,
    financialCategories: '/financial-categories',
    financialCategory: (id) => `/financial-categories/${id}`,
    financialTransactions: '/financial-transactions',
    features: '/features',
    resetPassword: '/reset-password',
    completeAccount: (id, hash) => `/complete-account/${id}/${hash}`,
    completeAccountSuccess: '/complete-account-success',
    signUpSuccess: '/sign-up-success',
    forgotPassword: '/forgot-password',
    emailVerified: '/email-verified',
    uploadProcess: '/upload-process',
    uploadProcessFinished: '/upload-process-finished',
    validateFormat: '/validate-format',
    analysis: '/analysis',
    // END: before login routes

    // START: logged in environment routes
    dashboard: userPrefix,
    personalInformation: userPrefix+'#'+sectionIds.personalInfo,
    groups: userPrefix+'/groups',
    group: (id) => `${userPrefix}/groups/${id}`,
    subscriptions: userPrefix+'#'+sectionIds.subscriptions,
    security: userPrefix+'#'+sectionIds.security,
    reagents: userPrefix+'/reagents',
    photo51Cloud: userPrefix+'/cloud-apps/photo51',
    accountEdit: userPrefix+'/account/settings',
    twoFactorAuthentication: userPrefix+'/enable-two-factor-authentication',
    twoFactorAuthenticationSuccess: userPrefix+'/two-factor-authentication-success',
    twoFactorChallenge: userPrefix+'/two-factor-challenge',
    groupsEdit: (id) => userPrefix+'/groups/edit/'+id,
    // END: logged in environment routes
}
