import React, {useMemo, useState} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Chart } from 'react-chartjs-2';
import useGet from "../../hooks/useGet";
import Loading from "../../components/Loading";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import BsTooltip from "react-bootstrap/Tooltip";
import Alert from "react-bootstrap/Alert";
import MoneyAmount from "../../components/MoneyAmount";
import {GrantModel} from "../../types/Models";
import moment from "moment";
import {backendEndpoints, concepts} from "../../utils";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {ExclamationTriangle, ExclamationTriangleFill} from "react-bootstrap-icons";
import {Link} from "react-router-dom";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

type Datasets = Array<{
    label: string,
    data: number[]
}>

interface ApiEmployeeLineChartData {
    line_chart: {
        datasets: Datasets
        xLabels: string[],
    }
    total_budget_left: number
    grants: GrantModel[]
    expenses_per_project: Record<number, number>
    employee_budget_left_per_grant: Record<string, number>
}

function EmployeeLineChart() {
    const [spreadingYears, setSpreadingYears] = useState(3);
    const {
        data,
        error: lineChartError,
        isLoading: loadingLineChart,
    } = useGet<ApiEmployeeLineChartData>({
        endpoint: 'dashboard/employee-line-chart',
        params: {
            years: spreadingYears
        },
    });

    const { line_chart: lineChart, grants } = data || {};
    const datasets: Datasets = useMemo(() => {
        // Round all numbers
        return lineChart?.datasets?.map((ds) => ({...ds, data: ds.data.map((i) => Math.round(i))}))
            || [];
    }, [lineChart]);

    const range = useMemo(() => {
        const r = { min: 0, max: 1000 };
        lineChart?.datasets?.forEach((ds) => {
            ds.data.forEach((i) => {
                if (i < r.min) r.min = i;
                if (i > r.max) r.max = i;
            });
        });
        return {
            min: r.min * 1.1,
            max: r.max * 1.1,
        };
    }, [lineChart]);

    if (loadingLineChart) return <Loading />;
    if (lineChartError) return <Alert variant="danger">{lineChartError}</Alert>;

    return (
        <>
            <Chart
                data={{ labels: lineChart.xLabels, datasets }}
                height="70"
                options={{
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: 'Employee line chart',
                        },
                    },
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            stacked: false,
                            ...range
                        },
                        'bar-stacked': {
                            display: false,
                            stacked: true,
                            ...range
                        },
                    }
                }}
                type="bar"
            />
            <div className="py-4" />
            <Row>
                <Col>
                    <h2>Employee budget left</h2>
                    <p>To change these number, please go to a grant and add the "Employement" budget category to a grant category.</p>
                    <table className="table table-light">
                        <tbody>
                            {Object.keys(data.employee_budget_left_per_grant).map((grantId) => (
                                <tr>
                                    <td>
                                        {grants.find((g) => `${g.id}` == grantId)?.name}
                                    </td>
                                    <td>
                                        <MoneyAmount
                                            amount={data.employee_budget_left_per_grant[grantId]}
                                            rounding
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Col>
                <Col xl={9} lg={12}>
                    <h2>Spending per {concepts.Grant}</h2>
                    <p>
                        Each {concepts.CostTransaction} is booked on a specific {concepts.Project} which is linked to a {concepts.Grant}.
                        The numbers in this table are retrieved by summation of those {concepts.CostTransactions}.&nbsp;
                    </p>
                    <table className="table table-light">
                        <thead>
                        <tr>
                            <th>Grant</th>
                            <th>Project end date</th>
                            <th>Project name</th>
                            <th>Total amount</th>
                            <th>Spent</th>
                            <th>Spent %</th>
                            <th>Budget left</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data?.grants && Object.values(data?.grants).map(({ id, name, total_amount, projects, usage_policy }, idx) => {
                            const project = Array.isArray(projects) && projects.length ? projects[0] : null;
                            const amountSpent = project ? data.expenses_per_project[project.id] : 0;

                            return (
                                <tr key={idx}>
                                    <td>{name}</td>
                                    <td>
                                        {project?.end_date && (
                                            <>
                                                {project?.end_date}
                                                <small className="d-block">
                                                    ({moment(project?.end_date).diff(moment(), 'months')} months)
                                                </small>
                                            </>
                                        )}
                                    </td>
                                    <td>
                                        {project?.name && (
                                            <Link to={backendEndpoints.project(project.id)} className="d-block text-truncate max-width-200">
                                                {project.name}
                                            </Link>
                                        )}
                                    </td>
                                    <td>
                                        <MoneyAmount amount={total_amount} />
                                    </td>
                                    <td>
                                        <MoneyAmount amount={-1 * amountSpent} />
                                    </td>
                                    <td>
                                        {Math.round(amountSpent / total_amount * 100)}%
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center gap-1">
                                            <MoneyAmount amount={total_amount - amountSpent} rounding />
                                            {usage_policy == '1' && (
                                                <OverlayTrigger
                                                    overlay={
                                                        <BsTooltip id="loseit">
                                                            You&apos;ll lose this money if you don't spend it.
                                                        </BsTooltip>
                                                    }
                                                >
                                                    <ExclamationTriangleFill className="text-info" />
                                                </OverlayTrigger>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colSpan={6} className="text-right">
                                Total:
                            </td>
                            <td>
                                <MoneyAmount amount={data.total_budget_left} rounding />
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                </Col>
            </Row>
        </>
    );
}

export default EmployeeLineChart;
