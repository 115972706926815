import Description from "../components/Description";
import useTitle from "../hooks/useTitle";

export default function NotFound() {
    useTitle('404 not found');

    return (
        <>
            <h1 className="gradient-color">Page not found.</h1>
            <Description>
                Error 404: this page does not exist.
            </Description>
        </>
    )
}
