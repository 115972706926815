import React, {useState} from 'react';
import axios from "axios";
import Loading from "../../Loading";
import {Alert, Form} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {objectToFormData} from "../../../utils/form";
import {useField} from "formik";
import {FileModel} from "../../../types/Models";
import Dropzone from "react-dropzone";
import {Trash3Fill} from "react-bootstrap-icons";
import moment from "moment";
import {standardDateTimeFormat} from "../../../utils/momentHelpers";

interface FileUploadProps {
    name: string,
    parentName: string,
    label: string
    directUploadData: Record<string, any>
    onSuccess: () => void
}

function FileUpload({ name, directUploadData, label, onSuccess, parentName }: FileUploadProps) {
    const [{ value },,] = useField<FileModel>(name);
    const [{ value: allFiles }, , { setValue: setAllFiles }] = useField<FileModel[]>(parentName);
    const [error, setError] = useState('');
    const [isUploading, setUploading] = useState(false);
    const [isDeleting, setDeleting] = useState(false);

    return (
        <Form.Group as={Row} className="mb-3" controlId={label}>
            <Form.Label column sm={2}>{label}</Form.Label>
            <Col sm={10}>
                {isUploading && <Loading />}
                {error && typeof error === 'string' && <Alert variant="danger">{error}</Alert>}
                {value && typeof value === 'object' ? (
                    <div className="d-inline-flex align-items-center">
                        <button
                            type="button"
                            className="text-danger bg-transparent border-0"
                            disabled={isDeleting}
                            onClick={() => {
                                setDeleting(true)
                                axios.delete(`/files/${value?.id}`)
                                    .then(() => {
                                        setAllFiles(allFiles.filter(f => f.id != value?.id))
                                        if (onSuccess) onSuccess();
                                    })
                                    .catch((e) => {
                                        setError(e.response?.data?.message || 'Something went wrong...')
                                    })
                                    .finally(() => {
                                        setDeleting(false)
                                    })
                            }}
                        >
                            {isDeleting ? <Loading /> : <Trash3Fill />}
                        </button>
                        <div>
                            <div>{value.name}</div>
                            <small>Uploaded {moment(value.created_at).format(standardDateTimeFormat)}</small>
                        </div>
                    </div>
                ) : (
                    <Dropzone
                        disabled={isUploading}
                        multiple={false}

                        onDrop={acceptedFiles => {
                            setUploading(false);
                            setError('');

                            // Prevent double upload
                            if (isUploading) {
                                return undefined;
                            }

                            const fd = objectToFormData(directUploadData);
                            if (acceptedFiles?.length === 1) {
                                fd.append('file', acceptedFiles[0]);
                                fd.append('name', acceptedFiles[0].name);
                            }
                            axios.post('/files?_with[]=upload_processes', fd)
                                .then((resp) => {
                                    setAllFiles(allFiles.concat([resp.data.data]))
                                    if (onSuccess) onSuccess();
                                })
                                .catch((e) => {
                                    setError(e.response?.data?.message || 'Something went wrong...')
                                })
                                .finally(() => {
                                    setUploading(false)
                                })
                        }}
                    >
                        {({getRootProps, getInputProps}) => (
                            <section className="bg-light py-4 px-2 text-center">
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} name={name} />
                                    <span>Drag 'n' drop here, or click to select file</span>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                )}
            </Col>
        </Form.Group>
    );
}


export default FileUpload;
