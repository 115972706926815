import React from 'react';
import {Column, useTable} from "react-table";
import { useNavigate } from "react-router";
import styles from "./Table.module.scss";

interface TableProps<T extends {}> {
    buildLink?: (row: T) => string
    memoisedColumns: Column<T>[]
    memoisedData: T[],
    maxHeight?: number,
}

export default function Table<T extends {}>({ memoisedData, memoisedColumns, buildLink, maxHeight = 400 }: TableProps<T>) {
    const navigate = useNavigate()
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({columns: memoisedColumns, data: memoisedData})

    return (
        // apply the table props
        <div className={styles.TableContainer} style={{ maxHeight: maxHeight || 'auto' }}>
            <table {...getTableProps()} className={`table table-striped ${styles.Table}`}>
                <thead>
                {// Loop over the header rows
                    headerGroups.map(headerGroup => (
                        // Apply the header row props
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {// Loop over the headers in each row
                                headerGroup.headers.map(column => (
                                    // Apply the header cell props
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                        </tr>
                    ))}
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                {Array.isArray(rows) && rows.length === 0 && (
                    <tr>
                        <td colSpan={headerGroups[0].headers.length} className="text-center gray-500 py-5">
                            No rows
                        </td>
                    </tr>
                )}
                {Array.isArray(rows) && rows.map(row => {
                    // Prepare the row for display
                    prepareRow(row)
                    return (
                        // Apply the row props
                        <tr
                            {...row.getRowProps()}
                            onClick={() => {
                                if (buildLink && row.original) {
                                    const destination = buildLink(row.original)
                                    if (destination) navigate(destination)
                                }
                            }}
                        >
                            {// Loop over the rows cells
                                row.cells.map(cell => {
                                    // Apply the cell props
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {// Render the cell contents
                                                cell.render('Cell')}
                                        </td>
                                    )
                                })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    );
}
