import React from 'react';
import styles from "./DataTable.module.scss";
import cx from "classnames";

interface DataTableProps {
    headers?: string[]
    rows: Array<string[]>
    cellClassName?: (row: string, index: number) => string
    showDottedRow?: boolean
}

function DataTable({ headers, rows, cellClassName, showDottedRow }: DataTableProps) {
    const length =  Array.isArray(rows) && rows.length > 0 ? rows[0].length : 0;

    return (
        <div className={styles.DataTableContainer}>
            <table className="table table-sm text-sm table-bordered">
                <thead>
                <tr>
                    <td className="bg-light" colSpan={length}><b className="text-sm">Sample data</b></td>
                </tr>
                <tr>
                    {Array.isArray(headers) && headers.map((label, idx) => (
                        <th key={idx} className={styles.Cell}>{label}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {Array.isArray(rows) && rows.map((row, idx) => (
                    <tr key={idx}>
                        {Array.isArray(row) && row.map((value, cellIdx) => (
                            <td
                                className={cx({
                                    [styles.Cell]: true,
                                    [cellClassName ? cellClassName(value, cellIdx) : '']: cellClassName,
                                })}
                                key={cellIdx}
                            >
                                {value}
                            </td>
                        ))}
                    </tr>
                ))}
                {showDottedRow && (
                    <tr>
                        {[...new Array(length)].map((value: string, idx: number) => (
                            <td key={idx}>...</td>
                        ))}
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
}

export default DataTable;
