import React, {useMemo, useState} from 'react';
import useGet from "../../hooks/useGet";
import { TransactionEntryModel} from "../../types/Models";
import {backendEndpoints} from "../../utils";
import {Accordion} from "react-bootstrap";
import MoneyAmount from "../MoneyAmount";
import FinancialCategorySpan from "../FinancialCategorySpan";
import {AccordionEventKey} from "react-bootstrap/AccordionContext";

function AccordionOverview({ queryParams }: { queryParams: { financial_number_id: number }}) {
    const { data: entries } = useGet<TransactionEntryModel[]>({
        endpoint: backendEndpoints.projectTransactionEntries(queryParams.financial_number_id)
    });
    const [key, setKey] = useState<AccordionEventKey>('');

    const { totalCosts, totalIncome } = useMemo(() => {
        let costs = 0;
        let income = 0;

        if (Array.isArray(entries)) {
            for (let i = 0; i < entries.length; i++) {
                if (parseFloat(entries[i].amount) >= 0) {
                    income += parseFloat(entries[i].amount);
                } else {
                    costs += parseFloat(entries[i].amount);
                }
            }
        }
        return {
            totalCosts: costs,
            totalIncome: income,
        }
    }, [entries]);

    return (
        <Accordion onSelect={(k) => setKey(k)} activeKey={key}>
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <h4 className="me-2">{entries?.length} transactions</h4>
                    <div className="d-inline-flex gap-3">
                        <div>
                            <h6>Income</h6>
                            <MoneyAmount amount={totalIncome} />
                        </div>
                        <div>
                            <h6>Costs</h6>
                            <MoneyAmount amount={totalCosts} />
                        </div>
                        <div>
                            <h6>Netto</h6>
                            <MoneyAmount amount={totalIncome + totalCosts} />
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body style={{ maxHeight: 500, overflowY: 'scroll' }}>
                    {key && (
                        <table className="table table-sm">
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Description</th>
                                <th>Supplier name</th>
                                <th>Amount</th>
                                <th>Cost type</th>
                            </tr>
                            </thead>
                            <tbody>
                            {Array.isArray(entries) ? entries.map((entry) => (
                                <tr key={entry.id}>
                                    <td>{entry.date}</td>
                                    <td>{entry.description}</td>
                                    <td>{entry.supplier_name}</td>
                                    <td><MoneyAmount amount={entry.amount} /></td>
                                    <td><FinancialCategorySpan costTypeId={entry.cost_type_id} /></td>
                                </tr>
                            )) : null}
                            </tbody>
                        </table>
                    )}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default AccordionOverview;
