import React, {useState, useRef, useContext, createContext} from 'react';
import cx from 'classnames';
import { ObjectSchema } from 'yup';
import {Formik, Form as FormikForm, useFormikContext} from "formik";
import axios, {AxiosError, AxiosResponse} from 'axios'
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Loading from "../Loading";

interface BackendFormProps {
    onSuccess?: (resp: AxiosResponse) => void,
    onError?: (resp: AxiosResponse) => void,
    enableReinitialize?: boolean
    showFooter: boolean
    targetMethod: 'POST' | 'GET' | 'PUT' | 'DELETE',
    children: JSX.Element[] | JSX.Element,
    targetEndpoint: string
    initialValues?: {},
    footerContent?: JSX.Element | null
    validationSchema: ObjectSchema<{}>
}

const BackendFormContext = createContext<{ footerContent: BackendFormProps['footerContent'], error?: string, feedback?: string }>({
    footerContent: null
})

export default function BackendForm({
    initialValues,
    children,
    targetEndpoint,
    targetMethod,
    onSuccess,
    enableReinitialize = false,
    footerContent,
    showFooter = true,
    onError,
    validationSchema,
}: BackendFormProps) {
    const timeoutRef = useRef<NodeJS.Timeout | undefined>()
    const [error, setError] = useState('')
    const [feedback, setFeedback] = useState('')

    return (
        <BackendFormContext.Provider value={{ error, feedback, footerContent }}>
            <Formik
                enableReinitialize={enableReinitialize || false}
                initialValues={initialValues || {}}
                onSubmit={(values, actions) => {
                    clearTimeout(timeoutRef.current)
                    setFeedback('')
                    setError('')

                    let prom;
                    if (targetMethod === 'POST') {
                        prom = axios.post(targetEndpoint, { ...values, token_name: 'web-app' })
                    } else if (targetMethod === 'PUT') {
                        prom = axios.put(targetEndpoint, { ...values, token_name: 'web-app' })
                    } else {
                        prom = axios.get(targetEndpoint);
                    }

                    prom
                        .then((response) => {
                            if (onSuccess) onSuccess(response)
                            const { message } = response.data;
                            if (message) setFeedback(message)

                            // Remove the feedback after some time.
                            timeoutRef.current = setTimeout(() => {
                                setFeedback('')
                            }, 3000);
                            return response;
                        })
                        .catch((e) => {
                            if (e instanceof AxiosError && !e.response) {
                                setError('No connection');
                                return;
                            }
                            const data = e.response.data;
                            const { message, errors } = data;

                            setError(message)
                            for (const errorsKey in errors) {
                                const value = errors[errorsKey]
                                actions.setFieldError(
                                    errorsKey,
                                    Array.isArray(value) ? value?.join(' ') : value,
                                )
                            }
                            if (onError) onError(e.response)
                        })
                        .finally(() => {
                            actions.setSubmitting(false)
                        })
                }}
                validationSchema={validationSchema}
            >
                {(props) => (
                    <FormikForm onSubmit={props.handleSubmit} action="post">
                        {children}
                        {showFooter && <BackendFormFooter />}
                    </FormikForm>
                )}
            </Formik>
        </BackendFormContext.Provider>
    );
}
export function BackendFormFooter({ showError = true, marginTop = true, buttonText = 'Submit', buttonSize = 'lg', className = '', center = false }:
                                      { buttonSize?: 'sm' | 'lg', showError?: boolean, marginTop?: boolean, buttonText?: string, className?: string, center?: boolean }
) {
    const { footerContent } = useContext(BackendFormContext);
    const { isSubmitting, submitForm } = useFormikContext();

    return (
        <>
            <div className={cx({
                'd-flex justify-content-end' : !center,
                'd-flex justify-content-center' : center,
                'mt-5' : marginTop,
            })}>
                {footerContent && (
                    <div className="me-2 flex-grow-1">{footerContent}</div>
                )}
                <Button
                    className={cx({
                        "ps-5": true,
                        "pe-5": !isSubmitting,
                        "pe-3": isSubmitting,
                        [className]: !!className,
                    })}
                    size={buttonSize}
                    variant="primary"
                    onClick={() => submitForm()}
                    disabled={isSubmitting}
                    type="submit"
                >
                    {buttonText}
                    {isSubmitting && <Loading />}
                </Button>
            </div>
            <div className="text-right">
                <BackendFormErrorFeedback showError={showError} />
            </div>
        </>
    )
}
function BackendFormErrorFeedback({ showError }: { showError: boolean }) {
    const { errors: validationErrors } = useFormikContext();
    const { error, feedback } = useContext(BackendFormContext);

    return <>
        {showError && error ? <Alert variant="danger">{error}</Alert> : null}
        {feedback && <Alert variant="success" className="d-inline-flex">{feedback}</Alert>}
    </>;
}
