import React, {useMemo} from 'react';
import {RecordData} from "./Models";
import {Field, useField} from "formik";

function Row({ idx, record, fieldKey, uniqueKey, name }: { idx: number, record: RecordData, fieldKey: string, uniqueKey: string | number, name: string }) {
    const fieldName = `tasks.edit-${name}-${uniqueKey}.${fieldKey}`;
    const [, { error },] = useField(fieldName);

    const shouldShow = idx < record[fieldKey].length;
    const fieldId = idx+'-input-'+fieldName;
    const hasOptions = record[fieldKey].length > 1;
    return (
        <>
            {shouldShow ? (
                <td width={100} className={error ? 'text-light bg-danger' : hasOptions ? 'bg-primary bg-opacity-25' : ''}>
                    {hasOptions && (
                        <Field name={fieldName} type="radio" id={fieldId} value={`${idx}`} className="me-1" />
                    )}
                    <label htmlFor={fieldId}>{record[fieldKey][idx]}</label>
                </td>
            ) : <td width={100} />}
        </>
    );
}

type Props = {
    originalRecords: RecordData[],
    name: string,
    indexKey: string|string[]
};

function ExistingRecordEditor({ originalRecords, name, indexKey }: Props) {
    const { recordsThatRequireAction, numberOfRows } = useMemo(() => {
        let nRows = 1;
        const filtered = originalRecords.filter((record) => {
            return Object.values(record).find((arr) => {
                if (arr.length > nRows) {
                    nRows = arr.length;
                }
                return arr.length > 1;
            });
        });
        return {
            recordsThatRequireAction: filtered,
            numberOfRows: nRows
        };
    }, [originalRecords]);

    if (!recordsThatRequireAction?.length) {
        return null;
    }
    const keys = Object.keys(recordsThatRequireAction[0]);

    return (
        <>
            <h6>{recordsThatRequireAction.length} conflict(s) found. Please select the correct option</h6>
            {recordsThatRequireAction.map((record) => {
                const uniqueKey = typeof indexKey === 'string' ? record[indexKey][0] : indexKey.map((k) => record[k][0]).join('.');
                return (
                    <table className="table table-light table-sm" key={uniqueKey}>
                        <thead>
                        <tr>
                            <td />
                            {keys.map((key, idx) => <th key={idx}>{key.replace(/_/g, ' ')}</th>)}
                        </tr>
                        </thead>
                        <tbody>
                            {[...new Array(numberOfRows)].map((unused, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td width={50}>{idx === 0 ? 'Old data' : 'New data'}</td>
                                        {keys.map((key) => {

                                            return (
                                                <Row
                                                    key={key}
                                                    fieldKey={key}
                                                    idx={idx}
                                                    record={record}
                                                    uniqueKey={uniqueKey}
                                                    name={name}
                                                />
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                );
            })}
        </>
    );
}

export default ExistingRecordEditor;
