import * as yup from "yup";

export const grantSchema = yup.object({
    total_amount: yup.number().required('Please fill in the amount'),
    grant_categories: yup.array().of(yup.object({
        id: yup.number(),
        // TODO
        // total_amount: yup.number().min(0),
    })),
    name: yup.string().required('Please fill in a name'),
    usage_policy: yup.number().required('Please select a usage policy'),
    specificity: yup.number().required('Please select a specificity'),
    group_id: yup.number().required('Please select a group'),
});

export const projectSchema = yup.object({
    name: yup.string().nullable(),
    external_id: yup.string().required('Without this number, we cannot connect any information to this project'),
    financial_number_id: yup.string().required('Without this number, we cannot connect any information to this project'),
    name_short: yup.string().nullable(),
    group_id: yup.number().required('Please select a group'),
    start_date: yup.date().required('A start date is required for budgeting.'),
    end_date: yup.date().required('An end date is required for budgeting.'),
    grant_id: yup.number().nullable(),
    employees: yup.array(yup.object({
        pivot: yup.object({
            employee_id: yup.number().required('Select employee'),
            start_date: yup.date().required('Select start date'),
            salary: yup.number().positive('Salaries cannot be negative').required('Select a salary'),
            fte: yup.number().positive('FTE must be between 0 and 1').max(1).required('Select a salary'),
        })
    })),
});

export const employeeSchema = yup.object({
    name: yup.string().required(),
    expected_final_employment_date: yup.string().nullable(),
    total_hours: yup.number().nullable(),
    total_fte: yup.number().nullable(),
    external_id: yup.number().required('A PeopleSoft ID is required to link data to this employee.'),
    group_id: yup.number().required(),
    projects: yup.array(yup.object({
        pivot: yup.object({
            project_id: yup.number().required('Select a project'),
            start_date: yup.date().required('Select a start date'),
            salary: yup.number().positive('Salaries cannot be negative').required('Select a salary'),
            fte: yup.number().positive('FTE must be between 0 and 1').max(1).required('Select a salary'),
        })
    })),
});

export const loginSchema = yup.object({
    email_or_username: yup.string().required(),
    password: yup.string().required(),
});

export const grantCategorySchema = yup.object({
    name: yup.string().required(),
    description: yup.string(),
    financial_categories: yup.array().of(yup.number()),
});

export const uploadSchema = yup.object({
    files: yup.array().of(yup.object({
        file_column_index: yup.number(),
    })).required(),
});

export const columnMapperSchema = yup.object({

});

export const formatErrorSchema = yup.object({
    replacement: yup.string(),
});
