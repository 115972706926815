import {useEffect} from 'react';

export const reloadEverywhere = (reloadEndpoint: string) => {
    window.dispatchEvent(new Event(`reload-${reloadEndpoint}`));
}

function useReloadEverywhere({ endpoint, reload }: { endpoint: string, reload: () => void }) {

    useEffect(() => {
        const listener = () => reload();
        const type = `reload-${endpoint}`;
        window.addEventListener(type, listener);

        return () => {
            window.removeEventListener(type, listener);
        }
    }, [endpoint, reload]);
}

export default useReloadEverywhere;
