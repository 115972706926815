import React, {useState} from 'react';
import PageHeader from "../../components/layout/page-header/PageHeader";
import useAuth from "../../hooks/useAuth";
import axios from "axios";
import {Alert} from "react-bootstrap";
import Loading from "../../components/Loading";
import {standardDateTimeFormat} from "../../utils/momentHelpers";
import moment from "moment";
import FileUpload from "../../components/forms/file-upload/FileUpload";
import {BackendForm} from "../../components/forms";
import {useField} from "formik";
import { UploadProcessModel} from "../../types/Models";
import {backendEndpoints, routes} from "../../utils";
import {uploadSchema} from "../../utils/validationSchemas";
import ColumnMapper from "./ColumnMapper";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FileSample from "./FileSample";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router";
import useCreateUploadProcess from "./useCreateUploadProcess";

const uploadFields: Array<{ label: string, description: string, pivotValue: string, mapperEndpoint: string }> = [{
    label: 'Employee information',
    description: '',
    pivotValue: 'employee-information',
    mapperEndpoint: backendEndpoints.columnMappingEmployeeInformation(),
}, {
    label: 'Transactions (Fin. sys.)',
    description: 'Ask your financial department to provide the bookings onto your projects.',
    pivotValue: 'financial-administration-transactions',
    mapperEndpoint: backendEndpoints.columnMappingFinAdminTransactions(),
}, {
    label: 'Project assignments (PeopleSoft)',
    description: 'Ask your financial department to provide the bookings onto your projects.',
    pivotValue: 'projects-people-soft',
    mapperEndpoint: backendEndpoints.columnMappingPeopleSoftProject(),
}, {
    label: 'Project assignments (Fin. sys.)',
    description: '',
    pivotValue: 'project-fin-system',
    mapperEndpoint: backendEndpoints.columnMappingFinSysProject(),
}];

function FileUploadFields({ name, uploadProcessId }: { name: string, uploadProcessId: number }) {
    const { reloadUser } = useAuth();
    const [{ value: items }] = useField<UploadProcessModel['files']>(name);

    return (
        <>
            {uploadFields.map((entry) => {
                const idx  = Array.isArray(items)
                    ? items?.findIndex((item) => item?.pivot?.meta_key === entry.pivotValue)
                    : -1;

                const file = idx > -1 ? items[idx] : null;

                return (
                    <React.Fragment key={entry.pivotValue}>
                        <FileUpload
                            parentName={name}
                            name={`${name}.${idx}`}
                            label={entry.label}
                            directUploadData={{
                                upload_processes: [{
                                    id: uploadProcessId,
                                    pivot: {
                                        meta_key: entry.pivotValue
                                    }
                                }],
                            }}
                            onSuccess={() => {
                                reloadUser();
                            }}
                        />
                        {file && (
                            <Row>
                                <Col sm={{ offset: 2, span: 10 }}>
                                    <FileSample fileId={file.id} />
                                </Col>
                            </Row>
                        )}
                        {file && entry.mapperEndpoint && (
                            <Row className="mb-3">
                                <Col sm={{ offset: 2, span: 10 }}>
                                    <ColumnMapper
                                        mapperEndpoint={entry.mapperEndpoint}
                                        fileId={file.id}
                                    />
                                </Col>
                            </Row>
                        )}
                    </React.Fragment>
                )
            })}
        </>
    );
}

function UploadProcess() {
    const { user, reloadUser } = useAuth();
    const navigate = useNavigate();
    const [validationError, setValidationError] = useState('');
    const { isCreating, error: uploadError } = useCreateUploadProcess();
    const error = validationError || uploadError;

    const validateColumnsAndMoveToAnalysis = () => {
        setValidationError('');
        axios.post(`/upload-processes/${user?.upload_process?.id}?_method=PUT`, { step_number: 2 })
            .then(() => {
                reloadUser();
                navigate(routes.validateFormat);
            })
            .catch((e) => {
                setValidationError(e.response?.data?.message || e.message);
            })
    };
    const validUploadProcess = user?.upload_process?.deleted_at ? null : user?.upload_process;

    return (
        <>
            <PageHeader header="Upload process" />

            {error && <Alert variant="danger">{error}</Alert>}
            {isCreating && <Loading />}

            {user && validUploadProcess && (
                <>
                    <p>
                        Welcome back, {user.first_name}.
                        You can continue this upload process created at {moment(validUploadProcess.created_at).format(standardDateTimeFormat)}.
                    </p>
                    <BackendForm
                        initialValues={{
                            files: validUploadProcess.files
                        }}
                        targetEndpoint={backendEndpoints.uploadProcess(validUploadProcess.id)}
                        targetMethod="PUT"
                        validationSchema={uploadSchema}
                        showFooter={false}
                        enableReinitialize
                    >
                        <FileUploadFields name="files" uploadProcessId={validUploadProcess.id} />

                        <div className="max-width-1000 text-right">
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Button
                                size="lg"
                                onClick={validateColumnsAndMoveToAnalysis}
                                className="px-5"
                                variant="success"
                            >
                                Next step: Validate format
                            </Button>
                        </div>
                    </BackendForm>
                </>
            )}
        </>
    );
}

export default UploadProcess;
