import React from 'react';
import Header from "../../Header";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router";

interface Props {
    children?: JSX.Element,
    header: string,
    returnButton?: {
        text: string
        url: string
    }
}

function PageHeader({ children, header, returnButton }: Props) {
    const navigate = useNavigate();

    return (
        <>
            {returnButton && (
                <Button onClick={() => navigate(returnButton.url)} variant="light" size="sm">
                    &larr;&nbsp;
                    {returnButton.text}
                </Button>
            )}
            <div className="d-flex align-items-center mb-2">
                <Header component="h1" className="mb-0 me-2">{header}</Header>
                {children && (
                    <div>
                        {children}
                    </div>
                )}
            </div>
        </>
    );
}

export default PageHeader;
