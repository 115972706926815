import React from 'react';
import useGet from "../hooks/useGet";
import {backendEndpoints, concepts, routes} from "../utils";
import Table from "../components/layout/table/Table";
import {Column} from "react-table";
import {Alert} from "react-bootstrap";
import Loading from "../components/Loading";
import PageHeader from "../components/layout/page-header/PageHeader";
import AddButton from "../components/button/AddButton";
import {useNavigate} from "react-router";
import PaginationBar from "../components/layout/PaginationBar";
import usePaginatedGet from "../hooks/usePaginatedGet";

const columns: Column[] = [
    { accessor: 'external_id', Header: 'Project ID (PeopleSoft)' },
    { accessor: 'financial_number_id', Header: 'Project ID (Fin.)'},
    { accessor: 'employees_count', Header: 'Employees'},
    { accessor: 'group.name', Header: 'Group name', Cell: ({ value }) => <div className="text-nowrap">{value}</div> },
    { accessor: 'grant.name', Header: 'Grant name', Cell: ({ value }) => <div className="text-truncate max-width-200">{value}</div> },
    { accessor: 'name_short', Header: 'Project abbreviation', Cell: ({ value }) => <div className="text-truncate max-width-200">{value}</div> },
    { accessor: 'name', Header: 'Name', Cell: ({ value }) => <div className="text-truncate max-width-300">{value}</div> },
    { accessor: 'start_date', Header: 'Start date', Cell: ({ value }) => <div className="text-nowrap">{value}</div> },
    { accessor: 'end_date', Header: 'End date', Cell: ({ value }) => <div className="text-nowrap">{value}</div> },
]

export default function Projects() {
    const navigate = useNavigate();
    const { data: projects, error, isLoading, pagination, pageIndex, setPageIndex  } = usePaginatedGet({
        endpoint: backendEndpoints.projects()+'?_with[]=grant&_with[]=group'
    });

    return (
        <>
            <PageHeader header={concepts.Projects}>
                <AddButton onClick={() => navigate(routes.project(':new'))} />
            </PageHeader>

            {pagination && (
                <PaginationBar pageIndex={pageIndex} setPageIndex={setPageIndex} pagination={pagination} />
            )}
            {isLoading && <Loading />}
            {error && <Alert variant="danger">{error}</Alert>}
            <Table
                memoisedData={Array.isArray(projects) ? projects : []}
                memoisedColumns={columns}
                buildLink={({ id }) => routes.project(id)}
                maxHeight={0}
            />
        </>
    );
}
