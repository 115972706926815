import React, {useEffect} from 'react';
import {routes} from "../utils";
import useAuth from "../hooks/useAuth";
import useTitle from "../hooks/useTitle";

export default function Logout() {
    useTitle('Logging out')
    const { logout } = useAuth()

    useEffect(() => {
        logout(undefined)
        window.location.href = routes.home+'?message='+encodeURI('Logged out successfully.')
    }, [])

    return (
        <>
            <h1>Logging out</h1>
            <p>You are being redirected to the home page.</p>
        </>
    );
}
