import React from 'react';
import {backendEndpoints, concepts, routes} from "../utils";
import Table from "../components/layout/table/Table";
import {Column} from "react-table";
import {Alert} from "react-bootstrap";
import Loading from "../components/Loading";
import PageHeader from "../components/layout/page-header/PageHeader";
import AddButton from "../components/button/AddButton";
import {useNavigate} from "react-router";
import usePaginatedGet from "../hooks/usePaginatedGet";
import PaginationBar from "../components/layout/PaginationBar";

const N_PREVIEW = 3;

const columns: Column[] = [
    { accessor: 'name', Header: 'Name' },
    { accessor: 'grant.name', Header: 'Grant' },
    {
        accessor: 'financial_categories',
        Header: 'Financial categories',
        Cell: ({ value }) => (
            <span>
                {value?.slice(0, N_PREVIEW).map((v: { name: string }) => v.name).join(', ')}
                {value?.length > N_PREVIEW && <> and {value?.length - N_PREVIEW} more</>}
            </span>
        ),
    },
    { accessor: 'id', Header: 'ID' },
]

export default function GrantCategories() {
    const navigate = useNavigate();
    const { data: grantCategories, error, isLoading, pagination, setPageIndex, pageIndex } = usePaginatedGet({
        endpoint: backendEndpoints.grantCategories()+'?_with[]=grant&_with[]=financial_categories'
    });

    return (
        <>
            <PageHeader header={concepts.GrantCategories} />
            <p>To create a new {concepts.GrantCategory}, please create or go to a {concepts.Grant}.</p>
            {pagination && (
                <PaginationBar
                    pageIndex={pageIndex}
                    setPageIndex={setPageIndex}
                    pagination={pagination}
                />
            )}
            {isLoading && <Loading />}
            {error && <Alert variant="danger">{error}</Alert>}
            <Table
                memoisedData={Array.isArray(grantCategories) ? grantCategories : []}
                memoisedColumns={columns}
                buildLink={({ grant }) => routes.grant(grant.id)}
                maxHeight={0}
            />
        </>
    );
}
