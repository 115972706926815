import React from 'react';
import {Accordion} from "react-bootstrap";
import {FinCategoryData} from "./Models";

function FoundCategoriesAccordion({ records } : { records: FinCategoryData[] }) {
    return (
        <Accordion>
            <Accordion.Item eventKey="all">
                <Accordion.Header>Found categories.</Accordion.Header>
                <Accordion.Body>
                    <table className="table table-sm">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Category name</th>
                        </tr>
                        </thead>
                        <tbody style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                        {records?.filter((newProject) => newProject.name.length).map((newProject) => (
                            <tr key={newProject.cost_type_id[0]}>
                                <td>{newProject.cost_type_id.length ? newProject.cost_type_id[0] : null}</td>
                                <td>{newProject.name[0]}</td>
                                <td>{newProject.cost_category_name.length ? newProject.cost_category_name[0] : null}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default FoundCategoriesAccordion;
