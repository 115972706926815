import {Route} from "react-router-dom";
import axios from "axios";
import Layout from "./components/layout/Layout";
import {QueryClient, QueryClientProvider} from "react-query";
import {Routes} from "react-router";
import NotFound from "./pages/NotFound";
import UserDashboard from "./pages/UserDashboard";
import ForgotPassword from "./pages/registering/ForgotPassword";
import ResetPassword from "./pages/registering/ResetPassword";
import {routes} from "./utils";
import Grants from "./pages/Grants";
import Grant from "./pages/Grant";
import {AuthProvider} from "./hooks/useAuth";
import Login from "./pages/registering/Login";
import GrantCategories from "./pages/GrantCategories";
import FinancialCategories from "./pages/FinancialCategories";
import UploadProcess from "./pages/upload-process/UploadProcess";
import ValidateFormatPage from "./pages/upload-process/ValidateFormatPage";
import Analysis from "./pages/upload-process/Analysis";
import Finished from "./pages/upload-process/Finished";
import Projects from "./pages/Projects";
import Employees from "./pages/Employees";
import Project from "./pages/Project";
import FinancialTransactions from "./pages/FinancialTransactions";
import QuickAddNewGrantCategory from "./pages/upload-process/QuickAddNewGrantCategoryButton";
import Employee from "./pages/Employee";
import Logout from "./pages/Logout";
import Settings from "./pages/Settings";
import AboutTheApp from "./pages/partials/AboutTheApp";

axios.defaults.baseURL = true ? 'http://0.0.0.0:80/api' :'/api';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.withCredentials = true;

// Create a client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: 5*60*1000,
        },
    },
})

function App() {
  return (
      <QueryClientProvider client={queryClient}>
          <AuthProvider>
              <QuickAddNewGrantCategory.ContextProvider>
                  <Routes>
                      <Route path={routes.forgotPassword} element={<Layout mainOnly><ForgotPassword /></Layout>} />
                      <Route path={routes.login} element={<Layout mainOnly><Login /></Layout>} />
                      <Route path={routes.resetPassword} element={<Layout mainOnly><ResetPassword /></Layout>} />

                      <Route path="*" element={(
                          <Layout>
                              <Routes>
                                  <Route path={routes.aboutTheApp} element={<AboutTheApp />} />
                                  <Route path={routes.uploadProcessFinished} element={<Finished />} />
                                  <Route path={routes.uploadProcess} element={<UploadProcess />} />
                                  <Route path={routes.validateFormat} element={<ValidateFormatPage />} />
                                  <Route path={routes.analysis} element={<Analysis />} />
                                  <Route path={routes.project(':id')} element={<Project />} />
                                  <Route path={routes.projects} element={<Projects />} />
                                  <Route path={routes.employee(':id')} element={<Employee />} />
                                  <Route path={routes.employees} element={<Employees />} />
                                  <Route path={routes.grants} element={<Grants />} />
                                  <Route path={routes.grant(':id')} element={<Grant />} />
                                  <Route path={routes.grantCategories} element={<GrantCategories />} />
                                  <Route path={routes.costs} element={<FinancialTransactions />} />
                                  <Route path={routes.financialCategories} element={<FinancialCategories />} />
                                  <Route path={routes.financialCategory(':id')} element={<FinancialCategories />} />
                                  <Route path={routes.dashboard} element={<UserDashboard />} />
                                  <Route path={routes.logout} element={<Logout />} />
                                  <Route path={routes.accountEdit} element={<Settings />} />
                                  <Route path="*" element={<NotFound />} />
                              </Routes>
                          </Layout>
                      )} />
                  </Routes>
              </QuickAddNewGrantCategory.ContextProvider>
          </AuthProvider>
      </QueryClientProvider>
  );
}

export default App;
