export const backendEndpoints: Record<string, (() => string) | ((id?: string | number) => string)> = {
    forgotPassword: () => '/forgot-password',
    resetPassword: () => '/reset-password',
    createGroup: () => '/groups',
    getGroups: () => '/groups',
    login: () => '/create-token',
    signUp: () => '/register',
    grants: () => '/grants',
    grant: (id) => `/grants/${id}`,
    grantUnassignedCategories: (id) => `/grants/${id}/compute-unassigned-categories-tasks`,
    grantUnassignedCategoriesForAll: () => `/grants/compute-unassigned-categories-tasks`,
    file: (id) => `/files/${id}`,
    fileHeaders: (id) => `/files/${id}/headers`,
    fileSample: (id) => `/files/${id}/sample`,
    uploadProcess: (id) => `/upload-processes/${id}`,
    formatError: (id) => `/format-errors/${id}`,
    grantCategories: () => `/grant-categories`,
    grantCategory: (id) => `/grant-categories/${id}`,
    projects: () => `/projects`,
    project: (id) => `/projects/${id}`,
    employees: () => `/employees`,
    employee: (id) => `/employees/${id}`,
    grantCategoriesOfGrant: (grantId) => `/grant-categories?grant_id=${grantId}`,
    financialCategories: () => `/financial-categories`,
    projectTransactionEntries: (projectId) => `/transaction-entries?project_financial_number_id=${projectId}`,
    financialTransactions: () => `/transaction-entries`,
    columnMappings: () => `/column-mappings`,
    finalise: (id) => `/upload-processes/${id}/finalise`,
    columnMappingEmployeeInformation: () => `/column-mappings/employee-information`,
    columnMappingNonEmployeeInformation: () => `/column-mappings/non-employee-information`,
    columnMappingFinSysProject: () => `/column-mappings/fin-sys-projects`,
    columnMappingPeopleSoftProject: () => `/column-mappings/peoplesoft-projects`,
    columnMappingFinAdminTransactions: () => `/column-mappings/fin-admin-transactions`,
    usagePolicy: () => 'enums/grant-usage-policy',
    specificity: () => "enums/grant-specificity",
    statisticsCategory: () => "enums/statistics-category",
}
