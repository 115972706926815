import React, {useMemo} from 'react';
import {backendEndpoints, concepts, routes} from "../utils";
import Tooltip from "react-bootstrap/Tooltip";
import useGet from "../hooks/useGet";
import {EmployeeModel, EmployeeProjectModel, GrantCategoryModel, GrantModel, ProjectModel} from "../types/Models";
import {Alert, OverlayTrigger} from "react-bootstrap";
import MoneyAmount from "../components/MoneyAmount";
import Loading from "../components/Loading";
import {Link} from "react-router-dom";
import EmployeeLineChart from "./dashboard/EmployeeLineChart";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {InfoCircleFill} from "react-bootstrap-icons";
import AboutTheApp from "./partials/AboutTheApp";
import moment from "moment";
import {standardDateFormat} from "../utils/momentHelpers";

const ACTIVE_RANGE_IN_MONTHS = 3;

function hasActiveProjectUntilRange(projects: ProjectWithPivot[]) {
    const now = moment();
    const later = moment().add(ACTIVE_RANGE_IN_MONTHS, 'months');
    return projects.find(({ pivot }) => {
        const { start_date, end_date } = pivot;
        const sd = moment(start_date);
        const ed = end_date && moment(end_date);
        const isActiveDuringEntirePeriod = sd && sd < now && (!ed || ed > later);
        return isActiveDuringEntirePeriod;
    });
}

interface GrantCategoryTableData {
    grants: GrantModel[],
    grant_categories: GrantCategoryModel[],
    spending_per_grant_category: Record<string, number>
    uncategorised_per_grant: Record<string, { pos: number, neg: number }>
}

interface ProjectWithPivot extends ProjectModel {
    pivot: EmployeeProjectModel
}

function UserDashboard({}) {
    const { data, error, isLoading } = useGet<GrantCategoryTableData>({ endpoint: 'dashboard/grant-categories-table' });
    const { data: transactionDateData } = useGet<{ latest: string, first: string, last_upload: string }>({ endpoint: 'transaction-entries/last-date' });
    const { data: tasksPerGrant, isLoading: loadingTasksPerGrant } = useGet<Array<{ id: string, name: string, number_of_tasks: number }>>({
        endpoint: backendEndpoints.grantUnassignedCategoriesForAll()
    });
    const { data: statCategories } = useGet<Array<{ value: string, label: string, last_upload: string }>>({
        endpoint: backendEndpoints.statisticsCategory(),
    });
    const { data: projects } = useGet<Array<ProjectModel & { grant: GrantModel[], employees: EmployeeModel[] }>>({
        endpoint: backendEndpoints.projects(),
        params: {
            _with: ['grant', 'employees']
        }
    });
    const { data: employees } = useGet<Array<EmployeeModel & { projects: ProjectWithPivot[] }>>({
        endpoint: backendEndpoints.employees(),
        params: {
            _with: ['projects']
        }
    });

    const expenses = useMemo(() => {
        const labels : Record<string, { expenses: number, label: string, description: string, budget: number }> = {
            other: {
                label: 'Unspecified',
                description: `The ${concepts.GrantCategory} amount minus the budget for the ${concepts.GrantCategories}`,
                expenses: 0,
                budget: Array.isArray(data?.grants)
                    ? data.grants.map((grant) => {
                        const categorisedBudget = data.grant_categories?.filter(gc => gc.grant_id === grant.id && gc.total_amount)
                            .reduce((acc, val) => acc + val.total_amount, 0);
                        return grant.total_amount - categorisedBudget;
                    }).reduce((acc, val) => acc + val, 0)
                    : 0,
            }
        };
        if (data?.grant_categories) {
            for (let i = 0; i < data?.grant_categories.length; i++) {
                const { statistics_category, id, total_amount } = data?.grant_categories[i];
                const spending = data.spending_per_grant_category[`${id}`];
                if (statistics_category) {
                    if (!labels[statistics_category]) {
                        labels[statistics_category] = {
                            budget: 0,
                            label: statCategories?.find(({ value }) => value === statistics_category)?.label || '',
                            expenses: 0,
                            description: ''
                        }
                    }
                    labels[statistics_category].expenses += spending;
                    if (total_amount) {
                        labels[statistics_category].budget += total_amount;
                    }
                } else {
                    labels.other.expenses += spending;
                }
            }
        }
        const items = Object.values(labels);
        return [...items.slice(1), items[0]];
    }, [statCategories, data]);

    if (data?.grants?.length === 0) {
        return <AboutTheApp />;
    }

    const projectsWithoutAGrant = projects?.filter((project) => !project.grant).length;
    const projectsWithoutEmployees = projects?.filter((project) => !project.employees?.length).length;
    const employeesWithoutProjects = employees?.filter(
        (emp) => emp.projects?.length === 0 || !hasActiveProjectUntilRange(emp.projects)
    );

    return (
        <>
            <h1>Dashboard</h1>
            <p>
                The database contains transactions
                between&nbsp;
                <b>{moment(transactionDateData?.first).format(standardDateFormat)}</b>
                &nbsp;and&nbsp;
                <b>{moment(transactionDateData?.latest).format(standardDateFormat)}.</b>
                <br />
                The last time new transaction data was uploaded was
                on <b>{moment(transactionDateData?.last_upload).format(standardDateFormat)}</b>.
            </p>
            {loadingTasksPerGrant && <Loading />}
            {tasksPerGrant?.length > 0 && (
                <Alert variant="danger" className="max-width-700">
                    <strong>Missing {concepts.FinancialCategories} assignments</strong>
                    <br />
                    {tasksPerGrant?.length} {concepts.Grants} require assigning
                    a {concepts.FinancialCategory} to a {concepts.GrantCategory}.
                    <ul>
                        {tasksPerGrant.map(({ name, id, number_of_tasks }) => (
                            <li><a className="link-primary" href={routes.grant(id)}>Assign</a> {number_of_tasks} for {name}</li>
                        ))}
                    </ul>

                </Alert>
            )}
            {employeesWithoutProjects?.length && (
                <Alert variant="danger" className="max-width-700">
                    <strong>{concepts.Employees} without {concepts.Projects}</strong>
                    <br />
                    {employeesWithoutProjects.length} {concepts.Employees} have not been fully
                    assigned to any {concepts.Project} during the
                    next {ACTIVE_RANGE_IN_MONTHS} months.
                    <br />
                    Please assign projects <a href={routes.employees}>here</a> for:<br/>
                    {employeesWithoutProjects.map(e => e.name).join(', ')}
                </Alert>
            )}
            {projectsWithoutAGrant > 0 && (
                <Alert variant="danger" className="max-width-700">
                    <strong>Missing {concepts.Grants}</strong>
                    <br />
                    {projectsWithoutAGrant} {concepts.Projects} have not been assigned to a {concepts.Grant}.
                    The {concepts.Project}'s {concepts.CostTransactions} are therefore not included in the graphs.
                    Please assign grants <a href={routes.projects}>here</a>.
                </Alert>
            )}
            {projectsWithoutEmployees > 0 && (
                <Alert variant="danger" className="max-width-700">
                    <strong>Project without employees</strong>
                    <br />
                    {projectsWithoutEmployees} {concepts.Projects} do not have
                    any {concepts.Employees} link to them.
                    Please assign projects <a href={routes.employees}>here</a>.
                </Alert>
            )}

            <h3>Employee funding</h3>
            <p>
                Estimated employee expenses per month for the next 3 years.
                The salaries are based on the project assignments.<br />
                The employee budget line is the budget
                for employee from the {concepts.Grants} minus the {concepts.CostTransactions}.
            </p>
            {!isLoading && <EmployeeLineChart />}

            <Row className="mt-5">
                <Col>
                    <h2>Spending per {concepts.GrantCategory}</h2>
                    {error && <Alert>{error}</Alert>}
                    {isLoading ? <Loading /> : (
                        <table className="table table-light">
                            <thead>
                            <tr>
                                <th>Grant</th>
                                <th>Grant Category</th>
                                <th>Budget</th>
                                <th>Spent</th>
                                <th>% left</th>
                                <th>% of grant</th>
                                {/*<th>Advice</th>*/}
                            </tr>
                            </thead>
                            <tbody>
                            {data?.grant_categories.map(({ id, name, grant_id, total_amount, statistics_category }, idx) => {
                                const grant = data.grants.find(g => g.id == grant_id);
                                const percentageLeft = 100 - Math.round(data.spending_per_grant_category[`${id}`] / total_amount * -100);

                                return (
                                    <tr key={idx}>
                                        <td>
                                            <Link to={routes.grant(grant?.id)} className="link-primary">
                                                {grant?.name}
                                            </Link>
                                        </td>
                                        <td>
                                            {name}
                                            <small className="d-block">
                                                {statCategories?.find(({ value }) => value === statistics_category)?.label || ''}
                                            </small>
                                        </td>
                                        <td>{total_amount ? <MoneyAmount amount={total_amount} rounding />: '-'}</td>
                                        <td>
                                            <MoneyAmount amount={data.spending_per_grant_category[`${id}`]} rounding />
                                        </td>
                                        <td>
                                            {grant?.total_amount
                                                ? (percentageLeft > 0 ? <span>{`${percentageLeft}%`}</span> : <span className="text-danger">None</span>)
                                                : '-'}
                                        </td>
                                        <td>
                                            {grant?.total_amount
                                                ? (
                                                    <span className="d-flex">
                                                <span>{`${Math.round(data.spending_per_grant_category[`${id}`] / grant.total_amount * 100)}% of`}&nbsp;</span>
                                                <MoneyAmount amount={grant.total_amount} rounding />
                                            </span>
                                                )
                                                : '-'}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    )}
                </Col>
                <Col>
                    <h2>Totals per {concepts.DashboardStatCategory}</h2>
                    <p>
                        Each {concepts.GrantCategory} can have a name, an amount an
                        a {concepts.DashboardStatCategory} used for these total statistics.

                    </p>
                    <table className="table table-light">
                        <thead>
                        <tr>
                            <th>Label</th>
                            <th>Total budget</th>
                            <th>Spent</th>
                            <th>Budget left</th>
                        </tr>
                        </thead>
                        <tbody>
                        {expenses.map(({ expenses, label, budget, description }) => (
                            <tr>
                                <td>
                                    {label}
                                    {description && (
                                        <OverlayTrigger placement="right" overlay={<Tooltip id={label}>{description}</Tooltip>}>
                                            <InfoCircleFill className="ms-2" />
                                        </OverlayTrigger>
                                    )}
                                </td>
                                <td>
                                    <MoneyAmount amount={budget} rounding />
                                </td>
                                <td>
                                    <MoneyAmount amount={expenses} rounding />
                                </td>
                                <td>
                                    <MoneyAmount amount={budget + expenses} rounding />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </Col>
            </Row>
        </>
    );
}

export default UserDashboard;
