import React from 'react';

function toK(amount: number) {
    if (Math.abs(amount) >= 1000000) {
        return `${(Math.round(amount / 1000) / 1000).toLocaleString('nl-NL', { maximumFractionDigits: 3 })}M`
    }
    if (Math.abs(amount) > 20000) {
        return `${Math.round(amount / 1000)}K`
    }
    return amount.toLocaleString('nl-NL', { minimumFractionDigits: 2 });
}

export default function MoneyAmount({ amount, rounding = false }: { amount: number | string, rounding?: boolean }) {
    const parsedFloat = parseFloat(`${amount}`);
    return (
        <div className={parsedFloat === 0 ? '' : (parsedFloat < 0 ? 'text-danger': 'text-success')}>
            &euro;
            {rounding ? toK(parsedFloat): parsedFloat.toLocaleString('nl-NL', { minimumFractionDigits: 2 })}
        </div>
    );
}

