import React, {useEffect} from 'react';
import {ErrorMessage, Field, useField} from "formik";
import {Form} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import cx from "classnames";

function text(o: FrontendOption | BackendItem) {
    if ("label" in o) return o.label;
    return o.name;
}

function value(o: FrontendOption | BackendItem) {
    if ("value" in o) return `${o.value}`;
    return `${o.id}`;
}

interface BackendItem {
    id: number,
    name: string
}

interface FrontendOption {
    value: string | number
    label: string
}

interface Props {
    options: Array<FrontendOption | BackendItem>
    label: string | JSX.Element,
    placeholder?: string | undefined,
    name: string,
    valueKey: keyof FrontendOption | keyof BackendItem
    labelWidth?: number | undefined
    forceDropdown?: boolean | undefined
    required?: boolean | undefined
}

export default function Select({ options, name, label, placeholder, labelWidth = 2, valueKey = 'value', forceDropdown, required = false }: Props) {
    const [field, { error }, { setValue }] = useField(name);

    useEffect(() => {
        // Formik Field does strict type checking, so everything must be a string.
        if (Number.isInteger(field.value)) setValue(`${field.value}`);
    }, [field.value]);

    return (
        <>
            <Form.Group as={Row} className="mb-2">
                <Form.Label
                    column
                    sm={labelWidth}
                    className={cx({
                        'pb-0': true,
                        "required-field": required,
                    })}
                >
                    {label}
                </Form.Label>
                <Col sm={12 - labelWidth}>
                    {(options.length <= 3 && !forceDropdown) ? (
                        <>
                            {placeholder && (
                                <div>
                                    <Field type="radio" value="" name={name} />
                                    <label>{placeholder}</label>
                                </div>
                            )}
                            {options.map((o) => (
                                <div key={value(o)}>
                                    <Field type="radio" value={value(o)} name={name} />
                                    <label>{text(o)}</label>
                                </div>
                            ))}
                        </>
                    ) : (
                        <Field
                            aria-label={label}
                            as="select"
                            className={`form-control ${field.value === '' ? 'gray-400' : ''}`}
                            name={name}
                        >
                            {placeholder && <option value="">--- {placeholder} ---</option>}
                            {options?.map((o) => (
                                <option value={value(o)} key={value(o)}>{text(o)}</option>
                            ))}
                        </Field>
                    )}
                    {error && <span className="text-danger">{error}</span>}
                </Col>
            </Form.Group>

        </>
    );
}
