import React, {useContext, useState} from 'react';
import {backendEndpoints, concepts} from "../../utils";
import {grantCategorySchema} from "../../utils/validationSchemas";
import {BackendForm, FloatingLabelField} from "../../components/forms";
import {Modal} from "react-bootstrap";
import {GrantCategoryModel} from "../../types/Models";
import {CategoryFields} from "../../components/grants/NewGrantCategoryModal";

export interface OpenQuickGrantCategoryProps {
    financialCategoryId: number,
    grantId: number
    onSuccess: (grantCategory: GrantCategoryModel) => void
}

const QuickAddNewGrantCategoryContext = React.createContext<{ open: (props: OpenQuickGrantCategoryProps) => void }>({ open: () => {} });

function QuickAddNewGrantCategory() {

}


function QuickAddNewGrantCategoryProvider({ children }: { children: React.ReactNode }) {
    const [data, setData] = useState<OpenQuickGrantCategoryProps | null>(null);
    const onHide = () => setData(null);

    return (
        <QuickAddNewGrantCategoryContext.Provider value={{ open: (props: OpenQuickGrantCategoryProps) => setData(props) }}>
            {children}
            {data && (
                <Modal show onHide={onHide}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add new {concepts.GrantCategory}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <BackendForm
                            showFooter
                            targetMethod="POST"
                            targetEndpoint={backendEndpoints.grantCategories()}
                            initialValues={{
                                name: '',
                                grant_id: data.grantId,
                                statistics_category: '',
                                financial_categories: [data.financialCategoryId],
                            }}
                            validationSchema={grantCategorySchema}
                            onSuccess={(resp) => {
                                if ("onSuccess" in data) {
                                    data.onSuccess(resp.data.data);
                                }
                                onHide();
                            }}
                        >
                            <CategoryFields.Name labelSize={6} />
                            <CategoryFields.StatCategory name="statistics_category" />
                        </BackendForm>
                    </Modal.Body>
                </Modal>
            )}
        </QuickAddNewGrantCategoryContext.Provider>
    );
}

export function useQuickNewGrantCategoryContext() {
    return useContext(QuickAddNewGrantCategoryContext);
}

QuickAddNewGrantCategory.ContextProvider = QuickAddNewGrantCategoryProvider;

export default QuickAddNewGrantCategory;

