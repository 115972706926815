import { useQuery } from 'react-query'
import axios, {AxiosError, AxiosResponse} from 'axios'
import {UseQueryOptions} from "react-query/types/react/types";
import {ServerResponse} from "http";
import {ApiPaginationData, ApiServerResponse} from "../types/Api";
import {useEffect, useMemo, useState} from "react";
import {routes} from "../utils";


export interface UseGetProps<TData extends any[] | Record<string, any>> {
    cacheTime?: UseQueryOptions['cacheTime']
    dataKey?: keyof ApiServerResponse<TData>,
    endpoint: string,
    params?: Record<string, any>,
    onError?: UseQueryOptions['onError']
    onSuccess?: UseQueryOptions['onSuccess']
    deps?: string[]
}

/**
 *
 * @param cacheTime
 * @param endpoint
 * @param onSuccess
 * @param onError
 * @param params
 * @param dataKey In nearly all cases, the JSON response contains a data key which contains the data you need. However,
 *                it may be the case that you need another key.
 */
export default function useGet<TData extends any[] | Record<string, any>>(
    { endpoint, onSuccess = undefined, onError = undefined, params = {}, dataKey = 'data', cacheTime = 0, deps = [] } : UseGetProps<TData>
) {
    const [pagination, setPagination] = useState<ApiPaginationData | null>();
    const {
        data: fullData,
        isLoading,
        error,
        refetch,
    } = useQuery<unknown, AxiosError<ApiServerResponse<any>>, AxiosResponse<ApiServerResponse<TData>>>(
        // The query will automatically update when this key changes
        [endpoint, JSON.stringify(params)].concat(deps),
        ({ signal }) => axios.get<ApiServerResponse<TData>>(endpoint, { params, signal }),
        { enabled: !!endpoint, retry: 1, onSuccess, onError, cacheTime }
    )

    const returnData: undefined | TData = (fullData ? fullData.data[dataKey] : undefined) as TData;

    useEffect(() => {
        if (error instanceof AxiosError && error.response?.status === 401) {
            window.location.href = routes.login;
        }
    }, [error]);

    useEffect(() => {
        if (!isLoading && fullData) {
            setPagination(fullData?.data ? fullData.data.pagination: null);
        }
    }, [isLoading, fullData]);

    return {
        data: returnData,
        isLoading,
        error: error instanceof AxiosError && error.response?.status === 403
            ? 'You do not have access.'
            : error?.response?.data?.message || error?.message,
        reload: refetch,
        pagination,
    }
}
