import React from 'react';
import {Icon} from "react-bootstrap-icons";

interface HeaderProps {
    component: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
    badgeText?: string,
    className?: string,
    children: string | JSX.Element | JSX.Element[],
    icon?: Icon,
    id?: string,
}

export default function Header({ badgeText, children, component: Component, className, icon: Icon, id }: HeaderProps) {
    return (
        <>
            {badgeText && (
                <div className="bg-light rounded-3 gray-600 text-sm d-inline-block py-2 px-4 mb-2">
                    {badgeText}
                </div>
            )}
            <div className={Icon ? 'd-flex justify-content-start align-items-center' : '' }>
                {Icon && <Icon size={36} className="me-3" />}
                <Component className={className} id={id}>{children}</Component>
            </div>
        </>
    );
}
