import React from 'react';
import {backendEndpoints, concepts, routes} from "../utils";
import Table from "../components/layout/table/Table";
import {Column} from "react-table";
import {Alert} from "react-bootstrap";
import Loading from "../components/Loading";
import PageHeader from "../components/layout/page-header/PageHeader";
import usePaginatedGet from "../hooks/usePaginatedGet";
import PaginationBar from "../components/layout/PaginationBar";
import AddButton from "../components/button/AddButton";
import {useNavigate} from "react-router";

const columns: Column[] = [
    { accessor: 'external_id', Header: 'External ID' },
    { accessor: 'name', Header: 'Name' },
    { accessor: 'total_fte', Header: 'Total FTE' },
    { accessor: 'total_hours', Header: 'Total Hours' },
    { accessor: 'projects.length', Header: 'Number of projects' },
    { accessor: 'expected_final_employment_date', Header: 'Expected final employement date' },
]

export default function Employees() {
    const navigate = useNavigate();
    const { data: employees, error, isLoading, pagination, pageIndex, setPageIndex } = usePaginatedGet({
        endpoint: backendEndpoints.employees()+'?_with[]=projects'
    });

    return (
        <>
            <PageHeader header={concepts.Employees}>
                <AddButton onClick={() => navigate(routes.employee(':new'))} />
            </PageHeader>
            {pagination && (
                <PaginationBar pageIndex={pageIndex} setPageIndex={setPageIndex} pagination={pagination} />
            )}
            {isLoading && <Loading />}
            {error && <Alert variant="danger">{error}</Alert>}
            <Table
                memoisedData={Array.isArray(employees) ? employees : []}
                memoisedColumns={columns}
                buildLink={({ id }) => routes.employee(id)}
                maxHeight={0}
            />
        </>
    );
}
