import React from 'react';
import useGet from "../../hooks/useGet";
import Loading from "../Loading";
import Select from "./Select";
import useReloadEverywhere from "../../hooks/useReloadEverywhere";

interface Props {
    endpoint: string,
    label: string | JSX.Element,
    placeholder?: string,
    name: string,
    labelWidth?: number
    hideIfNoOptions?: boolean
    forceDropdown?: boolean
}

type BackendItemFormat = { value: number, label: string } | { id: number, name: string };

export default function SelectBackend<TData extends BackendItemFormat>({ endpoint, name, label, placeholder, labelWidth, hideIfNoOptions, forceDropdown }: Props) {
    const { data: options, error, isLoading, reload } = useGet<Array<TData>>({ endpoint });
    useReloadEverywhere({ endpoint, reload });

    if (hideIfNoOptions && Array.isArray(options) && options.length === 0) return null;

    return (
        <div className="d-flex">
            <div className="flex-grow-1">
                <Select
                    options={Array.isArray(options) ? options : []}
                    name={name}
                    label={label}
                    placeholder={placeholder}
                    labelWidth={labelWidth}
                    valueKey="id"
                    forceDropdown={forceDropdown}
                />
                {error && <div className="text-danger">{error}</div>}
            </div>
            {isLoading && <Loading />}
        </div>
    );
}
