import React, {useCallback, useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {Alert, Badge, Modal} from 'react-bootstrap';
import {BackendForm, Checkboxes, FloatingLabelField} from "../components/forms";
import PageHeader from "../components/layout/page-header/PageHeader";
import SelectBackend from "../components/forms/SelectBackend";
import {grantSchema} from "../utils/validationSchemas";
import {backendEndpoints, concepts, routes} from "../utils";
import Loading from "../components/Loading";
import {isNew} from "../components/forms/FormHelpers";
import useGet from "../hooks/useGet";
import {
    CheckboxBackendProvider,
    ExtraContentProps,
    OptionModalProps
} from "../components/forms/checkbox/CheckboxHelpers";
import { GrantModel, GrantSpecificityModel, GroupModel, UsagePolicy} from "../types/Models";
import Description from "../components/Description";
import NewGrantCategoryModal, {CategoryFields} from "../components/grants/NewGrantCategoryModal";
import useUnassignedCategories from "../components/grants/useUnassignedCategories";
import {Field, useField} from "formik";

const Fields = {
    Amount: () => <FloatingLabelField label="Amount" name="total_amount" />,
    GrantSpecificity: () => <SelectBackend<GrantSpecificityModel> endpoint={backendEndpoints.specificity()} name="specificity" label="Specificity" />,
    Name: () => <FloatingLabelField label="Grant name" name="name" />,
    Group: () => <SelectBackend<GroupModel> endpoint="groups" name="group_id" label="Group"  />,
    UsagePolicy: () => <SelectBackend<UsagePolicy> endpoint={backendEndpoints.usagePolicy()} name="usage_policy" label="Usage policy" />,
};


type Timestamp = number;

function GrantCategoriesCheckboxes({ refreshTimestamp, existingGrant, setTimestamp }: { refreshTimestamp: Timestamp, existingGrant?: GrantModel, setTimestamp: (t: Timestamp) => void}) {
    const [{ value: specificity }] = useField('specificity');

    const optionModal = useCallback(({ close, selectedItem }: OptionModalProps) => (
        <NewGrantCategoryModal
            close={() => {
                setTimestamp(Date.now());
                close();
            }}
            grantId={existingGrant?.id}
            selectedItem={selectedItem}
        />

    ), [existingGrant]);

    const optionExtraContent = useCallback(({ option, index }: ExtraContentProps) => {
        const financialCategories = option.data?.financial_categories;

        return (option.data?.description || financialCategories?.length > 0) ? (
            <div className="bg-gray-100 p-1">
                <div className="max-width-500">
                    {`${specificity}` === '2' && (
                        <FloatingLabelField
                            label="Budget"
                            labelSize={6}
                            name={`grant_categories.${index}.total_amount`}
                        />
                    )}
                    <CategoryFields.StatCategory name={`grant_categories.${index}.statistics_category`} />

                </div>
                <Description className="text-sm">
                    {option.data?.description && (<>{option.data?.description} <br /></>)}

                    {financialCategories?.map((cat: { name: string, id: string }) => <Badge className="me-1" key={cat.id}>{cat.name}</Badge>)}
                </Description>
            </div>
        ) : null;
    }, []);

    return (
        <Checkboxes
            key={refreshTimestamp}
            options={existingGrant?.grant_categories?.map(c => ({ value: c.id, label: c.name, description: c.description, data: c })) || []}
            name="grant_categories"
            label="Grant categories"
            optionExtraContent={optionExtraContent}
            inPlaceModal
            optionModal={optionModal}
            emptyText="Please add a grant category"
            setValueModifier={(o) => o.data}
        />
    )
}


function UnassignedCategories({ grantId }: { grantId: number }) {
    const { tasks, isLoading, error } = useUnassignedCategories({ grantId });

    // Do not inform / bother the user that this is happening in the background.
    if (isLoading) return null;
    if (error) return <Alert variant="danger">{error}</Alert> ;

    return tasks?.length > 0 ? (
        <>
            <Alert variant="info">
                There are {tasks.length} {concepts.FinancialCategories} from
                transactions linked to this grant that need to be categorised.
                <br />
                {tasks.map((task) => (
                    <div key={task.code} className="bg-light px-3 d-inline-block mb-1 mr-1 rounded-pill">
                        {task.financial_category.name}
                    </div>
                ))}
            </Alert>
        </>
    ) : (
        <Alert variant="success">
            All {concepts.FinancialCategories} from transactions
            linked to this grant have been categorised.
        </Alert>
    );
}


function Grant({}) {
    const { id } = useParams();
    const [wasLoadedAlready, setLoadedAlready] = useState(false);
    const withParams = '?_with[]=grant_categories&_with[]=grant_categories.financial_categories';
    const [refreshTimestamp, setTimestamp] = useState(Date.now());
    const { data: existingGrant, isLoading } = useGet<GrantModel>({
        endpoint: isNew(id) ? '' : backendEndpoints.grant(id),
        params: {
            _with: ['grant_categories.financial_categories']
        },
        deps: [refreshTimestamp.toString()],
    });

    useEffect(() => {
        if (!wasLoadedAlready && existingGrant) {
            setLoadedAlready(true);
        }
    }, [existingGrant, wasLoadedAlready]);

    return (
        <CheckboxBackendProvider>
            {(isLoading && !wasLoadedAlready) ? <Loading /> : (
                <BackendForm
                    targetEndpoint={isNew(id) ? 'grants'+withParams : `grants/${id}${withParams}`}
                    targetMethod={isNew(id) ? 'POST' : 'PUT'}
                    initialValues={existingGrant ? existingGrant : {
                        total_amount: '',
                        name: '',
                        specificity: '1',
                        usage_policy: '1',
                        grant_categories: [],
                    }}
                    showFooter
                    validationSchema={grantSchema}
                    onSuccess={({ data }) => {
                        if (isNew(id)) {
                            window.location.href = routes.grant(data.data.id);
                        } else {
                            setTimestamp(Date.now());
                        }
                    }}
                >
                    <PageHeader
                        header={isNew(id) ? `Add ${concepts.Grant}` : `Edit ${concepts.Grant}`}
                        returnButton={{
                            text: `Back to ${concepts.Grants}`,
                            url: routes.grants,
                        }}
                    />
                    <Fields.Group />
                    <Fields.Name />
                    <Fields.Amount />
                    <Fields.GrantSpecificity />
                    <Fields.UsagePolicy />

                    {existingGrant?.id ? (
                        <Form.Group as={Row} controlId={id}>
                            <Form.Label column sm={2}>&nbsp;</Form.Label>
                            <Col>
                                <UnassignedCategories key={refreshTimestamp} grantId={existingGrant.id}/>
                            </Col>
                        </Form.Group>
                    ): <span />}
                    <GrantCategoriesCheckboxes
                        refreshTimestamp={refreshTimestamp}
                        existingGrant={existingGrant}
                        setTimestamp={setTimestamp}
                    />
                </BackendForm>
            )}
        </CheckboxBackendProvider>
    );
}

export default Grant;
