import React, {useEffect, useRef} from 'react';
import {BackendForm} from "../../components/forms";
import {backendEndpoints} from "../../utils";
import useGet from "../../hooks/useGet";
import {ColumnDefinitionModel, FileModel} from "../../types/Models";
import {columnMapperSchema} from "../../utils/validationSchemas";
import SelectBackend from "../../components/forms/SelectBackend";
import Loading from "../../components/Loading";
import {Alert} from "react-bootstrap";
import {BackendFormFooter} from "../../components/forms/BackendForm";
import {ArrowLeft} from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import {useField, useFormikContext} from "formik";

interface ColumnMapperProps {
    mapperEndpoint: string,
    fileId: number
}

type FileHeaders = { value: number, label: string }[]

interface ColumnMapperRowProps {
    item: ColumnDefinitionModel,
    fileId: ColumnMapperProps['fileId'],
    fileHeaders: FileHeaders,
    name: string,
}

function ColumnMapperRow({ item, fileId, fileHeaders, name }: ColumnMapperRowProps) {
    const [{ value },, { setValue }] = useField(name);

    const recommendations = Array.isArray(fileHeaders) && !value ? fileHeaders
        .filter(({ label }) => item.likely_columns?.indexOf(label) > -1)
        .slice(0, 1)
        : [];

    return (
        <div className="d-flex gap-3 align-items-start">
            <div className="d-inline-block flex-grow-1 max-width-600 w-100">
                <SelectBackend
                    endpoint={backendEndpoints.fileHeaders(fileId)}
                    label={(
                        <>
                            <div>{item.label}</div>
                            <small className="gray-700">{item.description}</small>
                        </>
                    )}
                    placeholder="Choose column"
                    name={name}
                    labelWidth={6}
                />
            </div>
            {recommendations.length > 0 && (
                <div className="d-inline-flex flex-column">
                    <small className="text-xs">Recommended:</small>
                    {recommendations.map(({ label, value }) => <Button className="py-0 mb-0" size="sm" variant="outline-primary" onClick={() => setValue(value)}><ArrowLeft /> {label}</Button>)}
                </div>
            )}
        </div>
    )
}

function AutoSave({ delay = 2000 }: { delay?: number }) {
    const { submitForm, values } = useFormikContext();
    const mounted = useRef(false);
    const timeoutRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if (mounted.current) {
            timeoutRef.current = setTimeout(
                () => {
                    submitForm();
                },
                delay
            );
        }
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [values]);

    useEffect(() => {
        mounted.current = true;
    }, []);

    return null;
}

function ColumnMapper({ mapperEndpoint, fileId }: ColumnMapperProps) {
    const { data: file, error: fileError, isLoading: fileLoading } = useGet<FileModel>({ endpoint: backendEndpoints.file(fileId)+'?_with[]=column_mapping_data'})
    const { data, isLoading, error } = useGet<ColumnDefinitionModel[]>({ endpoint: mapperEndpoint });
    const { data: fileHeaders } = useGet<FileHeaders>({ endpoint: backendEndpoints.fileHeaders(fileId) })

    const k = 'column_mapping_data';
    return (
        <>
            {fileError && <Alert variant="danger">{fileError}</Alert>}
            {error && <Alert variant="danger">{error}</Alert>}
            {isLoading || fileLoading
                ? <Loading />
                : (
                    <BackendForm
                        showFooter={false}
                        targetMethod="PUT"
                        targetEndpoint={backendEndpoints.file(fileId)+"?_with[]=column_mapping_data"}
                        validationSchema={columnMapperSchema}
                        initialValues={{
                            [k]: Array.isArray(data) ? data?.map(item => {
                                const fileColIndex = file.column_mapping_data.find((d) => d.system_column === item.key)?.file_column_index;
                                return ({
                                    file_column_index: typeof fileColIndex === 'number' ? fileColIndex : '',
                                    system_column: item.key,
                                })
                            }) : []
                        }}
                    >
                        <div>
                            {Array.isArray(data) && data?.map((item, idx) => (
                                <ColumnMapperRow
                                    name={`${k}.${idx}.file_column_index`}
                                    key={item.key}
                                    item={item}
                                    fileId={fileId}
                                    fileHeaders={fileHeaders}
                                />
                            ))}
                            <BackendFormFooter buttonText="Save mappings" marginTop={false} />
                            <AutoSave />
                        </div>
                    </BackendForm>
                )}
        </>
    );
}

export default ColumnMapper;
