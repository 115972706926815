import React from 'react';
import useGet from "../hooks/useGet";
import {backendEndpoints, concepts, routes} from "../utils";
import PageHeader from "../components/layout/page-header/PageHeader";
import Loading from "../components/Loading";
import {Alert} from "react-bootstrap";
import Table from "../components/layout/table/Table";
import {Column} from "react-table";
import usePaginatedGet from "../hooks/usePaginatedGet";
import PaginationBar from "../components/layout/PaginationBar";


const columns: Column[] = [
    { accessor: 'date', Header: 'Date' },
    { accessor: 'description', Header: 'Description' },
    { accessor: 'cost_type_id', Header: 'Cost Type ID' },
    { accessor: 'amount', Header: 'Amount' },
    { accessor: 'id', Header: 'ID' },
];

export default function FinancialTransactions() {
    const { data: transactions, error, isLoading, pageIndex, pagination, setPageIndex } = usePaginatedGet({
        endpoint: backendEndpoints.financialTransactions()+'?_orderBy=date'
    });

    return (
        <>
            <PageHeader header={concepts.CostTransactions} />
            <p>
                To add/edit {concepts.CostTransactions}, please use the {concepts.UploadProcess}.
                This way we can guarantee that the information in {concepts.AppName} is the
                consistent with the Financial department.
            </p>
            {pagination && (
                <PaginationBar pageIndex={pageIndex} pagination={pagination} setPageIndex={setPageIndex} />
            )}
            {isLoading && <Loading />}
            {error && <Alert variant="danger">{error}</Alert>}
            <Table
                memoisedData={Array.isArray(transactions) ? transactions : []}
                memoisedColumns={columns}
                buildLink={({ id }) => routes.cost(id)}
                maxHeight={0}
            />
        </>
    );
}

