import React, {useMemo} from 'react';
import { ApiPaginationData } from "../../types/Api";
import {Pagination} from "react-bootstrap";

function PaginationBar({ pageIndex, setPageIndex, pagination }: { pageIndex: number, setPageIndex: (n: number) => void, pagination: ApiPaginationData }) {
    const pages = useMemo(() => {
        return pagination?.number_of_pages ? [...new Array(pagination.number_of_pages)] : [];
    }, [pagination?.number_of_pages]);

    return (
        <div className="text-center">
            <Pagination className="d-inline-flex">
                <Pagination.First onClick={() => setPageIndex(0)} />
                <Pagination.Prev
                    disabled={pageIndex <= 0}
                    onClick={() => setPageIndex(Math.max(0, pageIndex - 1))}
                />
                {pages.map((_, idx) => Math.abs(pageIndex - idx) < 5 && (
                    <Pagination.Item
                        key={idx}
                        active={pageIndex === idx}
                        onClick={() => setPageIndex(idx)}
                        style={{ minWidth: 40 }}
                    >
                        {idx + 1}
                    </Pagination.Item>
                ))}
                <Pagination.Next
                    disabled={pageIndex >= pagination.number_of_pages - 1}
                    onClick={() => setPageIndex(Math.min(pagination.number_of_pages - 1, pageIndex + 1))}
                />
                <Pagination.Last onClick={() => setPageIndex(pagination.number_of_pages - 1)} />
            </Pagination>
        </div>
    );
}

export default PaginationBar;
