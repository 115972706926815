import React, {useState} from 'react';
import Description from "../../components/Description";
import {BackendForm, FloatingLabelField} from "../../components/forms";
import {backendEndpoints} from "../../utils";
import {formatErrorSchema} from "../../utils/validationSchemas";
import {BackendFormFooter} from "../../components/forms/BackendForm";
import styles from "./FileSample.module.scss";
import useGet from "../../hooks/useGet";
import DataTable from "../../components/data-table/DataTable";
import Loading from "../../components/Loading";

export type ErrorData = {
    message: string
    replacement?: string | null
    row: string[]
    id: number
};


function FormatError({ formatError, cellIdx, fileId }: { formatError: ErrorData, cellIdx: string, fileId: string }) {
    const { data, isLoading } = useGet<Array<{ label: string }>>({ endpoint: backendEndpoints.fileHeaders(fileId) })
    const [resolved, setResolved] = useState(false);

    const { message, id, row } = formatError;
    return resolved ? null :(
        <div className="mb-3 bg-light p-1">
            <div className="max-width-700">
                <Description>{message}</Description>
                <BackendForm
                    showFooter={false}
                    targetMethod="PUT"
                    targetEndpoint={backendEndpoints.formatError(id)}
                    validationSchema={formatErrorSchema}
                    initialValues={{
                        replacement: '',
                    }}
                    onSuccess={() => {
                        setResolved(true);
                    }}
                >
                    <div className="d-inline-flex align-items-start gap-2">
                        <FloatingLabelField name="replacement" label="Corrected value" labelSize={6} />
                        <BackendFormFooter marginTop={false} buttonSize="sm" buttonText="Save" />
                    </div>
                </BackendForm>
            </div>
            {isLoading && <Loading />}
            {data && (
                <DataTable
                    headers={data?.map((h) => h.label || '')}
                    rows={[row]}
                    cellClassName={(r, idx) => `${String(idx) === cellIdx ? 'bg-danger' : ''}`}
                    showDottedRow={false}
                />
            )}
        </div>
    );
}

export default FormatError;
