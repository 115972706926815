import React, {ReactNode, useCallback, useMemo} from 'react';
import {Formik, useField} from "formik";
import {Badge, Modal} from "react-bootstrap";
import {backendEndpoints, concepts} from "../../utils";
import {grantCategorySchema} from "../../utils/validationSchemas";
import {BackendForm, Checkboxes, FloatingLabelField} from "../forms";
import {GrantCategoryModel} from "../../types/Models";
import Button from "react-bootstrap/Button";
import cx from "classnames";
import Loading from "../Loading";
import {OptionModalProps} from "../forms/checkbox/CheckboxHelpers";
import useGet from "../../hooks/useGet";
import Alert from "react-bootstrap/Alert";
import useUnassignedCategories from "./useUnassignedCategories";
import SelectBackend from "../forms/SelectBackend";

export const CategoryFields = {
    Name: ({ labelSize = 2 }: { labelSize: number }) => (
        <FloatingLabelField label="Category name" name="name" labelSize={labelSize} autoFocus />
    ),
    Description: () => <FloatingLabelField label="Description" name="description" as="textarea" />,
    StatCategory: ({ name }: { name: string }) => (
        <SelectBackend
            forceDropdown
            labelWidth={6}
            name={name}
            label={concepts.DashboardStatCategory}
            placeholder="optional"
            endpoint={backendEndpoints.statisticsCategory()}
        />
    ),
};

const initialValues = {
    name: '',
    description: '',
    financial_categories: [],
};

function NewGrantCategoryModal({ close, selectedItem, grantId }: { grantId?: number } & OptionModalProps) {
    const [{ value: grantCategories },, { setValue }] = useField('grant_categories');
    const isNew = !selectedItem?.value;
    const { data: existingGrantCategory, error, isLoading } = useGet<GrantCategoryModel>({
        endpoint: selectedItem?.value ? backendEndpoints.grantCategory(selectedItem.value) : '',
        params: {
            _with: ['financial_categories']
        }
    });
    const { tasks } = useUnassignedCategories({ grantId });

    const finCategoryIds = useMemo(() => {
        return Array.isArray(tasks) ? tasks.map(t => `${t.financial_category.id}`): [];
    }, [tasks]);

    const Wrapper = useCallback(({ children }: { children: JSX.Element | JSX.Element[] }) => !grantId ? (
        <Formik
            validationSchema={grantCategorySchema}
            initialValues={initialValues}
            onSubmit={(values, formikHelpers) => {
                formikHelpers.setSubmitting(false)
                setValue(grantCategories.concat([{
                    name: values.name,
                    description: values.description,
                    data: {
                        financial_categories: values.financial_categories,
                    },
                }]));
                close();
            }}
        >
            {({ handleSubmit, isSubmitting }) => (
                <>
                    {children}
                    <div className="text-right">
                        <Button
                            className={cx({
                                "ps-5": true,
                                "pe-5": !isSubmitting,
                                "pe-3": isSubmitting,
                            })}
                            size="lg"
                            variant="primary"
                            type="submit"
                            disabled={isSubmitting}
                            onClick={() => handleSubmit()}
                        >
                            Submit
                            {isSubmitting && <Loading />}
                        </Button>
                    </div>
                </>
            )}
        </Formik>
    ) : (
        <BackendForm
            validationSchema={grantCategorySchema}
            initialValues={isNew ? { ...initialValues, grant_id: grantId } : {
                ...existingGrantCategory,
                description: existingGrantCategory.description || '',
                financial_categories: existingGrantCategory?.financial_categories?.map(({ id }) => id) || []
            }}
            onSuccess={(resp) => {
                if (isNew) {
                    setValue(grantCategories.concat([resp.data.data]));
                }
                close();
            }}
            targetEndpoint={isNew
                ? backendEndpoints.grantCategories()+'?_with[]=financial_categories'
                : backendEndpoints.grantCategory(existingGrantCategory?.id)+'?_with[]=financial_categories'}
            targetMethod={isNew ? 'POST': 'PUT'}
            showFooter
        >
            {children}
        </BackendForm>
    ), [existingGrantCategory, isNew, grantId]);
    const isUnassigned = (str: string) => finCategoryIds.includes(str);
    return (
        <Modal onHide={close} show size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Add {concepts.GrantCategory}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading && <Loading />}
                {error && <Alert className="alert-danger">{error}</Alert>}
                {(isNew || existingGrantCategory) && (
                    <Wrapper>
                        <CategoryFields.Name labelSize={2} />
                        <CategoryFields.Description />
                        <Checkboxes.Backend
                            endpoint={backendEndpoints.financialCategories()}
                            label={concepts.FinancialCategories}
                            name="financial_categories"
                            emptyText="Financial categories will be added automatically when uploading your financial data. You will be presented the opportunity to connect newly found financial categories to grant categories later."
                            optionExtraContent={({ option }) => {
                                return (
                                    <span className="float-end d-inline-flex gap-3">
                                        <span>{option.data?.cost_category_name}</span>
                                        {isUnassigned(`${option.data?.cost_type_id}`) && <Badge bg="danger">Unassigned</Badge>}
                                    </span>
                                );
                            }}
                            sorter={(a, b) => {
                                const aUn = isUnassigned(`${a.data?.cost_type_id}`);
                                const bUn = isUnassigned(`${b.data?.cost_type_id}`);

                                if (aUn && !bUn) return -1;
                                if (!aUn && bUn) return 1;

                                if (a.label < b.label) return -1;
                                if (a.label > b.label) return 1;
                                return 0;
                            }}
                        />
                    </Wrapper>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default NewGrantCategoryModal;
