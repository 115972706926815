import React, {useContext, useState} from "react";
import {Form} from "react-bootstrap";
import {PlusLg} from "react-bootstrap-icons";

export const CheckboxBackendContext = React.createContext<{ setModalOutsideForm: ((modal?: JSX.Element) => void) }>({ setModalOutsideForm: () => {} });

export function CheckboxBackendProvider({ children }: { children: JSX.Element }) {
    const [modalOutsideForm, setModalOutsideForm] = useState<JSX.Element | undefined>();
    return (
        <CheckboxBackendContext.Provider value={{ setModalOutsideForm }}>
            {children}
            {modalOutsideForm}
        </CheckboxBackendContext.Provider>
    )
}

export interface BackendOption {
    id: number,
    name?: undefined | string,
    description?: undefined | string,
}

export interface NewOption {
    name: string
    description?: undefined | string
}

export interface CheckboxPropsOption {
    value: string | number,
    label: string,
    disabled?: boolean,
    data?: Record<string, any>
}

export type ExtraContentProps = { option: Record<string, any>, index: number };

export interface CheckboxProps {
    options: Array<CheckboxPropsOption>,
    emptyText: string | undefined,
    label: string,
    name: string,
    onChange?: () => {},
    optionExtraContent?: undefined | (({ option, index }: ExtraContentProps) => JSX.Element | null),
    sorter?: (a: CheckboxPropsOption, b: CheckboxPropsOption) => number
    setValueModifier?: (a: CheckboxPropsOption) => string | number | CheckboxPropsOption['data']
}

export interface OptionModalProps<T extends { value: string | number } = CheckboxPropsOption> {
    close: () => void,
    selectedItem?: T
}

export interface NewCheckboxProps<T extends { value: string | number } = CheckboxPropsOption> {
    optionModal?: undefined | (({ close, selectedItem }: OptionModalProps<T>) => JSX.Element)
    inPlaceModal?: undefined | boolean
}

