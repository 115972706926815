import React, {useState} from 'react';
import cx from 'classnames';
import {ErrorMessage, Field} from "formik";
import InputGroup from "react-bootstrap/InputGroup";
import {Eye, EyeSlash} from "react-bootstrap-icons";
import styles from './FloatingLabelField.module.scss';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Form} from "react-bootstrap";

interface Props {
    autoFocus?: boolean,
    name: string,
    label: string,
    type?: string,
    required?: boolean,
    disabled?: boolean,
    helperText?: string,
    icon?: string,
    as?: 'textarea' | 'input'
    labelSize?: number
}

const MB = 'mb-2';

export default function FloatingLabelField({ name, label, type = 'text', autoFocus = false, icon, helperText, required, as = 'input', labelSize = 2, disabled = false }: Props) {
    const [forceTextType, setForceTextType] = useState(false);
    const id = label + name;

    const Icon = forceTextType ? Eye: EyeSlash;
    const showPassword = type === 'password' && (
        <Icon
            className={cx({
                [styles.ToggleViewingPassword]: true,
                'fs-4 gray-500': true,
            })}
            onClick={() => setForceTextType(!forceTextType)}
        />
    );

    const mainInput = (
        <Form.Group as={Row} className={icon || helperText ? undefined : MB} controlId={id}>
            <Form.Label
                column
                className={cx({
                    "required-field": required,
                })}
                sm={labelSize}
            >
                {label}
            </Form.Label>
            <Col sm={12 - labelSize}>
                <Field
                    className="form-control"
                    name={name}
                    id={id}
                    type={forceTextType ? 'text' : type}
                    placeholder={label}
                    autoFocus={autoFocus}
                    as={as}
                    disabled={disabled}
                />
                {helperText && <div className="text-right text-sm gray-500 mb-3">{helperText}</div>}
                <ErrorMessage name={name} className="text-danger" component="span" />
            </Col>
        </Form.Group>
    )

    return (
        <>
            {icon ? (
                <InputGroup className={helperText ? "mb-0" : MB}>
                    <InputGroup.Text className="fs-4 gray-500">
                        {icon}
                    </InputGroup.Text>
                    {mainInput}
                    {showPassword && <InputGroup.Text>{showPassword}</InputGroup.Text>}
                </InputGroup>
            ) : mainInput}
        </>
    );
}
